import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
    KeyboardAvoidingView,
    StyleSheet,
    ScrollView,
    Text,
    View,
    Platform,
    TouchableOpacity,
    Keyboard,
} from 'react-native';
import { Avatar, Header, Icon, Input, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth, db, storage } from '../firebase';
import firebase from 'firebase';
import { useRef } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import dateFormat from 'dateformat';
import { TouchableHighlight } from 'react-native';
import { Dimensions } from 'react-native';

import * as ImagePicker from 'expo-image-picker';
import { Image } from 'react-native-elements';
import { ActivityIndicator } from 'react-native';

function SingleChat({ route, navigation }) {
    const scrollViewRef = useRef();

    const user = auth.currentUser;

    const params = route.params;

    var precastMSGString = params.precastMSGString
        ? params.precastMSGString
        : '';

    const [msgList, setMSGList] = useState([]);
    const [messageInput, setMessageInput] = useState(precastMSGString);
    const textInputRef = useRef(null);

    function timestamptoDate(timestamp) {
        var today = new Date();
        var date = new Date();
        if (timestamp) {
            date = timestamp.toDate();
        }

        var formatDate = dateFormat(date, 'dd.mm.yy');

        if (date.getDate() === today.getDate()) {
            formatDate = dateFormat(date, 'HH:MM');
        } else if (date.getDate() === today.getDate() - 1) {
            formatDate = 'Gestern';
        }

        return formatDate;
    }

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: params.partnerData.displayName,
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerBackTitleVisible: false,
            headerTitleAlign: 'left',
            headerTitle: () => (
                <TouchableHighlight
                    underlayColor={'rgba(255,255,255,0.1)'}
                    onPress={() =>
                        navigation.navigate('userDetails', {
                            userID: params.partnerID,
                        })
                    }
                    style={{ flex: 1, justifyContent: 'center' }}
                >
                    <View style={styles.headerContainer}>
                        <Avatar
                            title={getInitials(params.partnerData.displayName)}
                            containerStyle={{
                                backgroundColor: Colors.primaryDark,
                            }}
                            rounded
                            source={{ uri: params.partnerData.photoURL }}
                        />
                        <Text style={styles.headerTitle}>
                            {params.partnerData.displayName}
                        </Text>
                    </View>
                </TouchableHighlight>
            ),
            headerTitleContainerStyle: {
                padding: 0,
                margin: 0,
                height: '100%',
                flex: 1,
            },
            headerRight: () => (
                <View>
                    <Icon
                        name="menu"
                        type="simple-line-icon"
                        size={18}
                        color={Colors.primary}
                        iconStyle={styles.headerMenuStyle}
                        reverse
                        reverseColor={'white'}
                        onPress={() => doNothing()}
                    />
                </View>
            ),
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    const pickImage = async () => {
        var docID = undefined;

        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
        });

        if (!result.cancelled) {
            const docRef = await db
                .collection('msg_rooms')
                .doc(params.chatID)
                .collection('messages')
                .add({
                    sender: user.uid,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    seenBy: [user.uid],
                    type: 'image',
                    photoURL: null,
                });

            docID = docRef.id;

            console.log(docID);

            db.collection('msg_rooms').doc(params.chatID).update({
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });

            const storageRef = storage.ref();
            var imagesRef = storageRef.child('msg/' + docID + '.jpg');
            var response = await fetch(result.uri);
            const blob = await response.blob();
            await imagesRef.put(blob);

            const photoURL = await imagesRef.getDownloadURL();

            db.collection('msg_rooms')
                .doc(params.chatID)
                .collection('messages')
                .doc(docID)
                .update({
                    photoURL: photoURL,
                });
        }
    };

    function doNothing(params) {
        alert('Dieses Feature ist leider noch in Arbeit', 'Work in Progress');
    }

    useEffect(() => {
        const unsub = db
            .collection('msg_rooms')
            .doc(params.chatID)
            .collection('messages')
            .orderBy('timestamp', 'asc')
            .onSnapshot(async (querySnapshot) =>
                setMSGList(
                    await Promise.all(
                        querySnapshot.docs.map(async (doc) => {
                            const chatPartner = await db
                                .collection('user')
                                .doc(
                                    params.users.filter(
                                        (data) => data != user.uid
                                    )[0]
                                )
                                .get();

                            return {
                                id: doc.id,
                                data: doc.data(),
                                chatPartnerID: chatPartner.id,
                                chatPartner: chatPartner.data(),
                            };
                        })
                    )
                )
            );

        return unsub;
    }, []);

    function sendMessage() {
        if (messageInput.trim()) {
            db.collection('msg_rooms')
                .doc(params.chatID)
                .collection('messages')
                .add({
                    message: messageInput,
                    sender: user.uid,
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    seenBy: [user.uid],
                    type: 'text',
                });
            db.collection('msg_rooms').doc(params.chatID).update({
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            setMessageInput('');
            textInputRef.current.focus();
            Keyboard.dismiss();
        }
        {
            setMessageInput('');
        }
    }

    function addAttachment(params) {
        alert('Diese Funktion ist leider noch in Arbeit!');
    }

    function updateLastSeen(messageID, chatPartnerID) {
        db.collection('msg_rooms')
            .doc(params.chatID)
            .collection('messages')
            .doc(messageID)
            .update({
                seenBy: [user.uid, chatPartnerID],
            });

        db.collection('msg_rooms').doc(params.chatID).update({
            tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }

    return (
        <SafeAreaView edges={['right', 'left']} style={styles.container}>
            <KeyboardAvoidingView
                style={{ flex: 1 }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                keyboardVerticalOffset={SPACING.MARGIN / 2}
            >
                <TouchableWithoutFeedback
                    style={{ flex: 1 }}
                    onPress={Keyboard.dismiss}
                >
                    <ScrollView
                        style={styles.chatContainer}
                        ref={scrollViewRef}
                        onContentSizeChange={() =>
                            scrollViewRef.current.scrollToEnd({ animate: true })
                        }
                    >
                        {msgList.map(
                            ({ id, data, chatPartnerID, chatPartner }) =>
                                data.sender != user.uid ? (
                                    data.type === 'text' ? (
                                        <ListItem
                                            key={id}
                                            containerStyle={
                                                styles.chatMessageContainer
                                            }
                                        >
                                            {updateLastSeen(id, chatPartnerID)}
                                            <Avatar
                                                size={SPACING.MARGIN * 1.25}
                                                iconStyle={{
                                                    backgroundColor:
                                                        Colors.primary,
                                                    width:
                                                        SPACING.MARGIN * 1.25,
                                                    height:
                                                        SPACING.MARGIN * 1.25,
                                                    justifyContent: 'center',
                                                }}
                                                icon={{
                                                    name: 'user',
                                                    type: 'simple-line-icon',
                                                    color: Colors.background,
                                                }}
                                                source={{
                                                    uri: chatPartner.photoURL,
                                                }}
                                                containerStyle={
                                                    styles.chatAvatar
                                                }
                                                rounded
                                            />
                                            <ListItem.Title
                                                style={styles.chatMessage}
                                            >
                                                {data.message}
                                            </ListItem.Title>
                                            <ListItem.Subtitle
                                                style={styles.timestamp}
                                            >
                                                {timestamptoDate(
                                                    data.timestamp
                                                )}
                                            </ListItem.Subtitle>
                                        </ListItem>
                                    ) : (
                                        <View
                                            key={id}
                                            style={{
                                                width: '100%',
                                                alignItems: 'flex-start',
                                                paddingLeft: SPACING.MARGIN,
                                                marginBottom: SPACING.MARGIN,
                                                marginTop: SPACING.MARGIN,
                                            }}
                                        >
                                            {updateLastSeen(id, chatPartnerID)}

                                            <Image
                                                containerStyle={{
                                                    width: 250,
                                                    height: 250,
                                                    borderRadius:
                                                        SPACING.MARGIN,
                                                    backgroundColor:
                                                        'rgba(0, 0,0,0)',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                resizeMode="cover"
                                                source={{ uri: data.photoURL }}
                                                PlaceholderContent={
                                                    <ActivityIndicator
                                                        style={{
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                        color={
                                                            Colors.accentDark
                                                        }
                                                        size="large"
                                                    />
                                                }
                                                placeholderStyle={{ flex: 1 }}
                                            />
                                            <Text
                                                style={{
                                                    fontWeight: '100',
                                                    marginTop:
                                                        SPACING.MARGIN / 2,
                                                }}
                                            >
                                                {timestamptoDate(
                                                    data.timestamp
                                                )}
                                            </Text>
                                        </View>
                                    )
                                ) : data.type === 'text' ? (
                                    <ListItem
                                        key={id}
                                        containerStyle={[
                                            styles.chatMessageContainer,
                                            { justifyContent: 'flex-end' },
                                        ]}
                                    >
                                        <ListItem.Subtitle
                                            style={styles.timestamp}
                                        >
                                            {timestamptoDate(data.timestamp)}
                                        </ListItem.Subtitle>
                                        <ListItem.Title
                                            style={styles.chatMessage}
                                        >
                                            {data.message}
                                        </ListItem.Title>
                                    </ListItem>
                                ) : (
                                    <View
                                        key={id}
                                        style={{
                                            width: '100%',
                                            alignItems: 'flex-end',
                                            paddingRight: SPACING.MARGIN,
                                            marginBottom: SPACING.MARGIN,
                                            marginTop: SPACING.MARGIN,
                                        }}
                                    >
                                        <Image
                                            containerStyle={{
                                                width: 250,
                                                height: 250,
                                                borderRadius: SPACING.MARGIN,
                                                backgroundColor: 'white',
                                                marginBottom:
                                                    SPACING.MARGIN / 3,
                                            }}
                                            resizeMode="cover"
                                            source={{ uri: data.photoURL }}
                                            PlaceholderContent={
                                                <ActivityIndicator
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                    color={Colors.accentDark}
                                                    size="large"
                                                />
                                            }
                                            placeholderStyle={{ flex: 1 }}
                                        />
                                        <Text style={{ fontWeight: '100' }}>
                                            {timestamptoDate(data.timestamp)}
                                        </Text>
                                    </View>
                                )
                        )}
                    </ScrollView>
                </TouchableWithoutFeedback>
                <View style={styles.inputMessageContainer}>
                    <Input
                        ref={textInputRef}
                        clearButtonMode="while-editing"
                        containerStyle={styles.inputMessage}
                        inputContainerStyle={styles.inputContainerStyle}
                        placeholder="Deine Nachricht ..."
                        onChangeText={(event) => setMessageInput(event)}
                        value={messageInput}
                        onSubmitEditing={sendMessage}
                        rightIcon={
                            <Icon
                                name="paper-clip"
                                type="simple-line-icon"
                                color={Colors.primary}
                                onPress={pickImage}
                            />
                        }
                        rightIconContainerStyle={styles.inputAddAttachment}
                    />
                    <TouchableOpacity onPress={sendMessage}>
                        <Icon
                            name="paper-plane"
                            type="simple-line-icon"
                            color={Colors.primary}
                            reverseColor={Colors.background}
                            containerStyle={styles.sendButton}
                            reverse
                        />
                    </TouchableOpacity>
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        /* backgroundColor: Colors.background, */
        paddingBottom: SPACING.MARGIN,
        maxHeight:
            Platform.OS === 'web'
                ? Dimensions.get('window').height - 65
                : Dimensions.get('window').height,
    },
    header: {
        backgroundColor: Colors.primary,
        padding: SPACING.MARGIN / 2,
    },
    chatContainer: {},
    chatMessageContainer: {
        backgroundColor: 'rgba(0,0,0,0)',
    },
    chatMessage: {
        backgroundColor: 'white',
        padding: SPACING.MARGIN / 2,
        borderRadius: SPACING.MARGIN / 2,
        overflow: 'hidden',
        maxWidth: '80%',
    },
    timestamp: {
        justifyContent: 'flex-end',
        fontWeight: '100',
    },
    chatAvatar: {
        position: 'absolute',
        bottom: -SPACING.MARGIN / 3.5,
        left: SPACING.MARGIN / 2.5,
        zIndex: 5,
    },
    inputMessageContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        paddingTop: SPACING.MARGIN / 2,
    },
    inputMessage: {
        flex: 1,
        height: 65,
        padding: 0,
        margin: 0,
    },
    inputContainerStyle: {
        padding: SPACING.MARGIN / 2,
        backgroundColor: 'white',
        borderRadius: SPACING.MARGIN,
        borderBottomWidth: 0,
        paddingHorizontal: SPACING.MARGIN,
        paddingRight: SPACING.MARGIN / 2,
    },
    inputAddAttachment: {
        paddingLeft: SPACING.MARGIN / 2,
    },
    sendButton: {},

    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerTitle: {
        color: 'white',
        paddingLeft: SPACING.MARGIN / 2,
        fontSize: 16,
    },
    headerMenuStyle: {
        paddingHorizontal: SPACING.MARGIN / 2,
    },
});

export default SingleChat;
