import dateFormat from 'dateformat';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import { Platform } from 'react-native';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { Avatar, FAB, Icon, Input, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { db, storage } from '../firebase';
import * as ImagePicker from 'expo-image-picker';
import firebase from 'firebase';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';

function GroupDetail({ navigation, route }) {
    const params = route.params;

    //console.log(params);

    const [groupDetail, setGroupDetail] = useState([]);
    const [apartmentDetail, setApartmentDetail] = useState([]);
    const [allUser, setAllUser] = useState([]);
    const [tempGroupName, setTempGroupName] = useState('');

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Gruppen Details',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerBackTitleVisible: false,
            headerShown: false,
        });
    }, []);

    useEffect(() => {
        if (JSON.stringify(groupDetail) !== JSON.stringify([])) {
            setAllUser([]);
            groupDetail.users.map((user) => {
                db.collection('user')
                    .doc(user)
                    .get()
                    .then((userDetail) =>
                        setAllUser((allUser) => [...allUser, userDetail])
                    );
            });
        } else {
            setAllUser([]);
            params.user.map((user) => {
                db.collection('user')
                    .doc(user)
                    .get()
                    .then((userDetail) =>
                        setAllUser((allUser) => [...allUser, userDetail])
                    );
            });
        }

        const unsubGroupDetails = db
            .collection('msg_rooms')
            .doc(params.groupID)
            .onSnapshot((snapshot) => {
                setGroupDetail(snapshot.data());
                setTempGroupName(snapshot.data().title);
            });

        const unsub = db
            .collection('msg_rooms')
            .doc(params.groupID)
            .onSnapshot((querySnapshot) => {
                setGroupDetail(querySnapshot.data());
            });

        return unsub, unsubGroupDetails, setAllUser([]), setApartmentDetail([]);
    }, []);

    useEffect(() => {
        if (apartmentDetail.id !== groupDetail.selectedApartment) {
            db.collection('apartment')
                .doc(groupDetail.selectedApartment)
                .get()
                .then((res) =>
                    setApartmentDetail({ id: res.id, data: res.data() })
                );
        }
    });

    function getAllUser() {
        if (groupDetail.users) {
            return groupDetail.users.length;
        } else {
            return params.user.length;
        }
    }

    function timestamptoDate(timestamp) {
        var today = new Date();
        var date = new Date();
        if (timestamp) {
            date = timestamp.toDate();
        }

        var formatDate = dateFormat(date, 'dd.mm.yy');

        if (date.getDate() === today.getDate()) {
            formatDate = dateFormat(date, 'HH:MM');
        } else if (date.getDate() === today.getDate() - 1) {
            formatDate = 'Gestern';
        }

        return formatDate;
    }

    function openApartmentDetails(id) {
        navigation.navigate('apartmentDetail', {
            apartmentID: id,
            groupID: params.groupID,
        });
    }

    function addUserToGroup() {
        navigation.navigate('addUserToGroup', {
            user: groupDetail.users,
            groupID: params.groupID,
        });
    }

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
        });

        if (!result.cancelled) {
            const storageRef = storage.ref();
            var imagesRef = storageRef.child('pb/' + params.groupID + '.jpg');
            var response = await fetch(result.uri);
            const blob = await response.blob();
            await imagesRef.put(blob);

            const photoURL = await imagesRef.getDownloadURL();

            db.collection('msg_rooms').doc(params.groupID).update({
                photoURL: photoURL,
                tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            });
        }
    };

    function doNothing(params) {}

    function saveGroupName() {
        db.collection('msg_rooms').doc(params.groupID).update({
            title: tempGroupName,
        });
    }

    return (
        <SafeAreaView
            edges={['left', 'right', 'top´']}
            style={[styles.container, { backgroundColor: 'rgba(0,0,0,0)' }]}
        >
            <ScrollView
                style={{
                    flex: 1,
                    paddingTop: Platform.OS === 'web' ? SPACING.MARGIN : 0,
                }}
                contentContainerStyle={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}
            >
                <FAB
                    style={{
                        alignSelf: 'flex-start',
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                    color={Colors.accent}
                    icon={{
                        name: 'arrow-left',
                        type: 'simple-line-icon',
                        color: 'white',
                    }}
                    onPress={() => {
                        navigation.goBack();
                    }}
                />

                <Avatar
                    containerStyle={styles.userProfilePicture}
                    source={{ uri: groupDetail.photoURL }}
                    title={getInitials(groupDetail.title)}
                    titleStyle={{ fontSize: 36 }}
                    rounded
                >
                    <Icon
                        containerStyle={styles.uploadProfilePicture}
                        size={SPACING.MARGIN / 1.5}
                        name="cursor"
                        type="simple-line-icon"
                        rounded
                        reverse
                        color={Colors.accent}
                        reverseColor={Colors.background}
                        onPress={pickImage}
                    />
                </Avatar>
                <Input
                    inputStyle={styles.title}
                    errorStyle={{ padding: 0, margin: 0 }}
                    value={tempGroupName}
                    containerStyle={{
                        width: '50%',
                    }}
                    inputContainerStyle={{ borderBottomWidth: 0 }}
                    onChangeText={setTempGroupName}
                    onSubmitEditing={saveGroupName}
                />
                {/* <View
                    style={{
                        flexDirection: 'row',
                        paddingBottom: SPACING.MARGIN,
                    }}
                >
                    <Icon
                        name="speech"
                        type="simple-line-icon"
                        reverse
                        color={Colors.primary}
                    />
                </View> */}
                <View
                    style={{
                        marginTop: SPACING.MARGIN,
                        marginBottom: SPACING.MARGIN / 2,
                        width: '90%',
                        alignSelf: 'center',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text
                        style={{
                            fontWeight: '300',
                            fontSize: 16,
                        }}
                    >
                        Wunsch WG
                    </Text>
                    <Text
                        style={{
                            textDecorationStyle: 'solid',
                            textDecorationLine: 'underline',
                            color: Colors.primary,
                        }}
                        onPress={() =>
                            navigation.navigate('selectApartment', {
                                selectedApartment:
                                    groupDetail.selectedApartment,
                                groupID: params.groupID,
                            })
                        }
                    >
                        Auswählen
                    </Text>
                </View>
                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={{
                        width: '90%',
                        alignSelf: 'center',
                        padding: SPACING.MARGIN / 2,
                        paddingHorizontal: SPACING.MARGIN,
                        borderRadius: SPACING.MARGIN,
                    }}
                    onPress={() => {
                        apartmentDetail.data
                            ? openApartmentDetails(
                                  groupDetail.selectedApartment
                              )
                            : navigation.navigate('selectApartment', {
                                  selectedApartment:
                                      groupDetail.selectedApartment,
                                  groupID: params.groupID,
                              });
                    }}
                >
                    <Avatar
                        containerStyle={{ backgroundColor: Colors.primaryDark }}
                        icon={{ name: 'camera', type: 'simple-line-icon' }}
                        rounded
                        size={SPACING.MARGIN * 2}
                        source={
                            apartmentDetail.data
                                ? apartmentDetail.data.photoURL !== null
                                    ? { uri: apartmentDetail.data.photoURL }
                                    : null
                                : null
                        }
                    />
                    <ListItem.Content style={{ flex: 1 }}>
                        <ListItem.Title style={[styles.listItemTitle, {}]}>
                            {apartmentDetail.data
                                ? apartmentDetail.data.name
                                : 'Keine WG ausgewählt'}
                        </ListItem.Title>
                        <ListItem.Subtitle
                            numberOfLines={1}
                            style={styles.listItemSubTitle}
                        >
                            {apartmentDetail.data
                                ? apartmentDetail.data.description
                                : ''}
                        </ListItem.Subtitle>
                    </ListItem.Content>
                    <Icon
                        name="arrow-right"
                        type="simple-line-icon"
                        color="grey"
                        size={18}
                    />
                </ListItem>
                <View
                    style={{
                        backgroundColor: 'white',
                        width: '90%',
                        alignSelf: 'center',
                        marginTop: SPACING.MARGIN,
                        borderRadius: SPACING.MARGIN,
                        paddingVertical: SPACING.MARGIN,
                        paddingBottom: 0,
                        overflow: 'hidden',
                    }}
                >
                    <View
                        style={{
                            paddingBottom: SPACING.MARGIN,
                            marginHorizontal: SPACING.MARGIN,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: '300',
                            }}
                        >
                            Alle Mitglieder:
                        </Text>
                        <Text
                            style={{
                                fontSize: 16,
                                fontWeight: '100',
                            }}
                        >
                            {getAllUser()}
                        </Text>
                    </View>
                    {groupDetail.users ? (
                        groupDetail.users.map((item, index) => (
                            <ListItem
                                key={index}
                                style={{ paddingHorizontal: 0 }}
                                topDivider
                                onPress={() =>
                                    navigation.navigate('userDetails', {
                                        userID: allUser[index].id,
                                    })
                                }
                            >
                                <Avatar
                                    title={getInitials(
                                        allUser[index]
                                            ? allUser[index].data().displayName
                                            : null
                                    )}
                                    containerStyle={{
                                        backgroundColor: Colors.primaryDark,
                                    }}
                                    rounded
                                    size={SPACING.MARGIN * 2}
                                    source={
                                        allUser[index]
                                            ? {
                                                  uri: allUser[index].data()
                                                      .photoURL,
                                              }
                                            : null
                                    }
                                />
                                <ListItem.Content>
                                    <ListItem.Title
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        {allUser[index]
                                            ? allUser[index].data().displayName
                                            : 'Fehler'}
                                    </ListItem.Title>
                                    <ListItem.Subtitle
                                        style={{
                                            fontSize: 13,
                                            fontWeight: '200',
                                        }}
                                    >
                                        {allUser[index]
                                            ? allUser[index].data().status
                                            : 'Fehler'}
                                    </ListItem.Subtitle>
                                </ListItem.Content>
                            </ListItem>
                        ))
                    ) : (
                        <></>
                    )}
                    <ListItem
                        containerStyle={{
                            backgroundColor: Colors.accent,
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingVertical: SPACING.MARGIN / 1.5,
                        }}
                        topDivider
                        onPress={() => addUserToGroup()}
                    >
                        <Icon
                            name="plus"
                            type="simple-line-icon"
                            color="white"
                            size={SPACING.MARGIN}
                        />
                        <ListItem.Title
                            style={{
                                fontSize: 14,
                                color: 'white',
                            }}
                        >
                            User Hinzufügen
                        </ListItem.Title>
                    </ListItem>
                </View>

                <View style={styles.mapContainer}>
                    <MapView
                        style={{ flex: 1 }}
                        region={{
                            'latitude': 51.095521140506136,
                            'longitude': 10.595558871152813,
                            'latitudeDelta': 8,
                            'longitudeDelta': 8,
                        }}
                        provider={PROVIDER_GOOGLE}
                    >
                        {Platform.OS !== 'web' ? (
                            <>
                                {allUser ? (
                                    allUser.map((user, index) =>
                                        user.data().geolocation ? (
                                            <Marker
                                                key={index}
                                                coordinate={{
                                                    'latitude':
                                                        user.data().geolocation
                                                            .latitude,
                                                    'longitude':
                                                        user.data().geolocation
                                                            .longitude,
                                                }}
                                                title={user.data().displayName}
                                                description={
                                                    user.data().homeTown
                                                }
                                                icon={require('../assets/icons/simple-line-icons_user.png')}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                                {/*apartmentDetail && apartmentDetail !== [] ? (
                                    <Marker
                                        coordinate={{
                                            'latitude':
                                                apartmentDetail.geolocation
                                                    .latitude,
                                            'longitude':
                                                apartmentDetail.geolocation
                                                    .longitude,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )*/}
                            </>
                        ) : (
                            <>
                                {allUser ? (
                                    allUser.map((user, index) =>
                                        user.data().geolocation ? (
                                            <MapView.Marker
                                                key={index}
                                                coordinate={{
                                                    'latitude':
                                                        user.data().geolocation
                                                            .latitude,
                                                    'longitude':
                                                        user.data().geolocation
                                                            .longitude,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                                {/*apartmentDetail ? (
                                    <MapView.Marker
                                        coordinate={{
                                            'latitude':
                                                apartmentDetail.geolocation
                                                    .latitude,
                                            'longitude':
                                                apartmentDetail.geolocation
                                                    .longitude,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )*/}
                            </>
                        )}
                    </MapView>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    userProfilePicture: {
        backgroundColor: 'grey',
        height: SPACING.MARGIN * 4,
        width: SPACING.MARGIN * 4,
        marginTop: SPACING.MARGIN * 2,
    },
    uploadProfilePicture: {
        position: 'absolute',
        bottom: -SPACING.MARGIN / 1.75,
        right: -SPACING.MARGIN / 1.75,
    },
    title: {
        fontWeight: '500',
        fontSize: 18,
        marginTop: SPACING.MARGIN,
        marginBottom: SPACING.MARGIN / 4,
        textAlign: 'center',
        borderBottomWidth: 0,
        padding: 0,
    },
    subtitle: {
        fontWeight: '300',
        fontSize: 14,
        marginBottom: SPACING.MARGIN / 2,
    },
    listItem: {
        justifyContent: 'space-between',
        paddingHorizontal: SPACING.MARGIN * 1,
    },
    listItemTitle: {
        fontSize: 16,
        fontWeight: '500',
    },
    listItemSubtitle: {
        fontSize: 15,
        fontWeight: '400',
    },
    mapContainer: {
        backgroundColor: 'lightgrey',
        width: '90%',
        height: SPACING.MARGIN * 15,
        marginVertical: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        overflow: 'hidden',
    },
});

export default GroupDetail;
