import React, { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native';
import { Text } from 'react-native';
import { Badge, Button, FAB, Image, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { auth, db } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { View } from 'react-native';
import dateFormat from 'dateformat';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native';
import firebase from 'firebase';
import { Linking } from 'react-native';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';

function ApartmentDetail({ route, navigation }) {
    const params = route.params;

    const [apartmentDetail, setApartmentDetail] = useState([]);
    const [specialProperties, setSpecialProperties] = useState([]);
    const [supportUserData, setSupportUserData] = useState([]);

    var supportUserID = 'MVhowIOc7wasftJZt2bFJbeZXtq2';

    const user = auth.currentUser;

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
            title: 'WG Details',
        });
    }, []);

    useEffect(() => {
        const unsubProperties = db
            .collection('apartment')
            .doc(params.apartmentID)
            .collection('properties')
            .onSnapshot((querySnapshot) =>
                setSpecialProperties(
                    querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        data: doc.data(),
                    }))
                )
            );
        db.collection('user')
            .doc(supportUserID)
            .get()
            .then((res) => setSupportUserData(res.data()));

        const unsub = db
            .collection('apartment')
            .doc(params.apartmentID)
            .onSnapshot((querySnapshot) =>
                setApartmentDetail(querySnapshot.data())
            );

        return unsub, unsubProperties;
    }, [params.apartmentID]);

    function getFreeRooms(free, reserved) {
        return free - reserved;
    }

    function timestampToDate(timestamp) {
        var date = new Date();

        if (timestamp) {
            date = timestamp.toDate();
        }

        return dateFormat(date, 'dd.mm.yy');
    }

    function openChat(chatID, users, partner, partnerID, precastMSGString) {
        navigation.navigate('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
            precastMSGString: precastMSGString,
        });
    }
    async function createChat(partnerID, partnerData, precastMSGString) {
        var res = await isChatCreated(partnerID);
        if (res.result) {
            db.collection('msg_rooms')
                .add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'single',
                    users: [partnerID, user.uid],
                })
                .then((res) =>
                    openChat(
                        res.id,
                        [partnerID, user.uid],
                        partnerData,
                        partnerID,
                        precastMSGString
                    )
                );
        } else {
            openChat(
                res.chatID,
                [partnerID, user.uid],
                partnerData,
                partnerID,
                precastMSGString
            );
        }
    }

    async function openURL(url) {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            await Linking.openURL(url);
        }
    }

    async function isChatCreated(id) {
        const chatUserArray = [id, user.uid];
        const chatUserArray2 = [user.uid, id];

        const res = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray)
            .get();

        const res2 = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray2)
            .get();

        //console.log(res.empty && res2.empty);

        var resChatId;

        if (!res.empty) {
            res.docs.forEach((doc) => (resChatId = doc.id));
        } else if (!res2.empty) {
            res2.docs.forEach((doc) => (resChatId = doc.id));
        }

        return { result: res.empty && res2.empty, chatID: resChatId };
    }

    return (
        <SafeAreaView style={styles.container} edges={['left', 'right']}>
            <ScrollView
                style={{
                    flex: 1,
                }}
                contentContainerStyle={{
                    flexGrow: 1,
                }}
            >
                <Image
                    source={{ uri: apartmentDetail.photoURL }}
                    containerStyle={{
                        width: '100%',
                        height: 350,
                        backgroundColor: 'black',
                    }}
                    height={350}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingHorizontal: SPACING.MARGIN,
                        marginTop: -SPACING.MARGIN,
                    }}
                >
                    <FAB
                        containerStyle={[styles.backFAB, {}]}
                        icon={{
                            name: 'arrow-left',
                            type: 'simple-line-icon',
                            color: 'white',
                        }}
                        color={Colors.accent}
                        onPress={() => navigation.goBack()}
                    />
                    {
                        <FAB
                            containerStyle={[styles.backFAB, {}]}
                            icon={{
                                name: 'speech',
                                type: 'simple-line-icon',
                                color: 'white',
                            }}
                            color={Colors.primary}
                            title="Bewerben"
                            iconRight
                            onPress={() =>
                                createChat(
                                    supportUserID,
                                    supportUserData,
                                    'Thema: Bewerben für die folgende WG: ' +
                                        apartmentDetail.name
                                )
                            }
                        />
                    }
                </View>
                <View style={styles.detailContainer}>
                    <Text style={styles.detailTitle}>
                        {apartmentDetail.name}
                    </Text>
                    <Text style={styles.detailSubtitle}>
                        {apartmentDetail.adress}
                    </Text>

                    <Text style={styles.detailSmallTitle}>Beschreibung:</Text>
                    <Text>{apartmentDetail.description}</Text>
                    {apartmentDetail.geolocation ? (
                        <View style={styles.mapContainer}>
                            <MapView
                                style={{ flex: 1 }}
                                region={{
                                    'latitude':
                                        apartmentDetail.geolocation.latitude,
                                    'longitude':
                                        apartmentDetail.geolocation.longitude,
                                    'latitudeDelta': 0.5,
                                    'longitudeDelta': 0.5,
                                }}
                                provider={PROVIDER_GOOGLE}
                            >
                                {Platform.OS !== 'web' ? (
                                    <>
                                        {apartmentDetail.geolocation ? (
                                            <Marker
                                                coordinate={{
                                                    'latitude':
                                                        apartmentDetail
                                                            .geolocation
                                                            .latitude,
                                                    'longitude':
                                                        apartmentDetail
                                                            .geolocation
                                                            .longitude,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {apartmentDetail.geolocation ? (
                                            <MapView.Marker
                                                coordinate={{
                                                    'latitude':
                                                        apartmentDetail
                                                            .geolocation
                                                            .latitude,
                                                    'longitude':
                                                        apartmentDetail
                                                            .geolocation
                                                            .longitude,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </MapView>
                        </View>
                    ) : (
                        <></>
                    )}
                    <Text style={styles.detailSmallTitle}>Eigenschaften:</Text>
                    <View style={styles.detailListContainer}>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Wohnfläche:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.apartment_size + 'm^2'}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Wohnräume (verfügbar):
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.rooms_free +
                                        '(' +
                                        getFreeRooms(
                                            apartmentDetail.rooms_free,
                                            apartmentDetail.rooms_reserved
                                        ) +
                                        ')'}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Stockwerk:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.floor}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Kaltmiete:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.rent + ',00€'}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Nebenkosten:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.extra_cost + ',00€'}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Zuletzt Renoviert:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.renovated}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Beziehbar ab:
                                </ListItem.Title>
                                <ListItem.Title style={styles.listItemSubtitle}>
                                    {apartmentDetail.obtainable}
                                </ListItem.Title>
                            </ListItem>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.detailSmallTitle}>
                            Besondere Eigenschaften:
                        </Text>
                        {specialProperties.map(({ id, data }) => (
                            <View
                                key={id}
                                style={styles.detailListItemContainer}
                            >
                                <ListItem
                                    containerStyle={styles.detailListItem}
                                >
                                    <ListItem.Title
                                        style={styles.listItemTitle}
                                    >
                                        {data.title + ':'}
                                    </ListItem.Title>
                                    <ListItem.Title
                                        style={styles.listItemSubtitle}
                                    >
                                        {data.value}
                                    </ListItem.Title>
                                </ListItem>
                            </View>
                        ))}

                        {apartmentDetail.apartmentURL ? (
                            <Button
                                containerStyle={{ marginTop: SPACING.MARGIN }}
                                buttonStyle={{
                                    borderRadius: SPACING.MARGIN,
                                    paddingVertical: SPACING.MARGIN / 2,
                                    paddingHorizontal: SPACING.MARGIN,
                                    backgroundColor: Colors.primaryDark,
                                }}
                                onPress={() =>
                                    openURL(apartmentDetail.apartmentURL)
                                }
                                title="Hier bekommst du noch mehr Infos"
                            />
                        ) : (
                            <></>
                        )}
                    </View>

                    <Text
                        style={{
                            paddingTop: SPACING.MARGIN,
                            fontWeight: '100',
                        }}
                    >
                        Inseriert seit:
                        {' ' + timestampToDate(apartmentDetail.created_at)}
                    </Text>
                    <Text
                        style={{
                            fontWeight: '100',
                        }}
                    >
                        Zuletzt bearbeitet am:
                        {' ' + timestampToDate(apartmentDetail.last_editet_at)}
                    </Text>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: { flex: 1 },
    backFAB: {
        position: 'relative',
    },
    detailContainer: {
        alignSelf: 'center',
        width: '70%',
        zIndex: 1,
        paddingTop: SPACING.MARGIN,
    },
    detailTitle: {
        paddingTop: SPACING.MARGIN,
        fontWeight: '600',
        fontSize: 24,
    },
    detailSubtitle: {
        fontWeight: '300',
        fontSize: 14,
        paddingBottom: SPACING.MARGIN,
    },
    detailSmallTitle: {
        fontSize: 16,
        fontWeight: '500',
        paddingBottom: SPACING.MARGIN / 2,
        paddingTop: SPACING.MARGIN,
    },
    detailListContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    detailListItemContainer: {
        minWidth: 250,
        width: Platform.OS === 'web' ? '49%' : '100%',
    },
    detailListItem: {
        backgroundColor: 'rgba(0,0,0,0)',
        padding: 0,
        paddingVertical: SPACING.MARGIN / 4,
        margin: 0,
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    listItemTitle: {
        fontWeight: '400',
    },
    listItemSubtitle: {
        fontWeight: '300',
    },
    mapContainer: {
        backgroundColor: 'lightgrey',
        width: '90%',
        height: SPACING.MARGIN * 6,
        marginVertical: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        overflow: 'hidden',
    },
});

export default ApartmentDetail;
