import React, { useEffect, useState } from 'react';
import { Icon, Overlay, Text } from 'react-native-elements';
import Modal from 'modal-react-native-web';
import { Platform, View } from 'react-native';
import { SPACING } from '../../assets/colors/colors';
import { db, auth } from '../../firebase';

function VersionOverlay() {
    const [isVisible, setVisible] = useState(false);
    const [versionInfo, setVersionInfo] = useState([]);

    function dismissOverlay() {
        setVisible(false);
        console.log('Test');
    }

    const user = auth.currentUser;

    useEffect(() => {
        const unsubVersionInfo = db
            .collection('version_notification')
            .orderBy('timestamp', 'desc')
            .limit(1)
            .get()
            .then((snapshot) => {
                setVersionInfo({
                    data: snapshot.docs[0].data(),
                    id: snapshot.docs[0].id,
                });
                updateSeenBy({
                    data: snapshot.docs[0].data(),
                    id: snapshot.docs[0].id,
                });
            });

        return unsubVersionInfo;
    }, []);

    function updateSeenBy({ data, id }) {
        if (data) {
            if (data.seenBy.includes(user.uid)) {
                setVisible(false);
            } else {
                db.collection('version_notification')
                    .doc(id)
                    .update({
                        seenBy: [...data.seenBy, user.uid],
                    });
                setVisible(true);
            }
        } else {
            setVisible(false);
        }
    }

    return (
        <>
            <Overlay
                ModalComponent={Platform.OS === 'web' ? Modal : undefined}
                isVisible={isVisible}
                onDismiss={dismissOverlay}
                onBackdropPress={dismissOverlay}
                ariaHideApp={false}
                fullScreen={false}
                overlayStyle={{
                    padding: SPACING.MARGIN,
                    borderRadius: SPACING.MARGIN / 2,
                    maxWidth: '60%',
                }}
            >
                <Icon
                    name="close"
                    containerStyle={{
                        position: 'absolute',
                        right: -SPACING.MARGIN,
                        top: -SPACING.MARGIN,
                    }}
                    reverse
                    onPress={dismissOverlay}
                />
                <View>
                    <Text h3>
                        {versionInfo.data ? versionInfo.data.title : ''}
                    </Text>
                    <Text
                        style={{
                            fontSize: 20,
                            fontWeight: '300',
                            marginBottom: SPACING.MARGIN,
                        }}
                    >
                        Version:{' '}
                        {versionInfo.data ? versionInfo.data.versionNumber : ''}
                    </Text>
                    <Text>
                        {versionInfo.data
                            ? versionInfo.data.text.replaceAll('<br/>', '\n')
                            : ''}
                    </Text>
                </View>
            </Overlay>
        </>
    );
}

export default VersionOverlay;
