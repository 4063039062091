import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { auth, db } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { Text } from 'react-native';
import firebase from 'firebase';
import { View } from 'react-native';

export default function ApartmentList({ navigation }) {
    const [apartments, setApartments] = useState([]);
    const [supportUserData, setSupportUserData] = useState([]);
    const user = auth.currentUser;

    var supportUserAccountID = 'MVhowIOc7wasftJZt2bFJbeZXtq2';

    var isSupport =
        user.uid === 'skXog9L260OTDKTLUT9bbWE1aNm1' ||
        user.uid === supportUserAccountID;

    function openApartmentDetail(apartmentID) {
        navigation.navigate('apartmentDetail', { apartmentID: apartmentID });
    }

    function openChat(chatID, users, partner, partnerID, precastMSGString) {
        navigation.navigate('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
            precastMSGString: precastMSGString,
        });
    }
    async function createChat(partnerID, partnerData, precastMSGString) {
        var res = await isChatCreated(partnerID);
        if (res.result) {
            db.collection('msg_rooms')
                .add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'single',
                    users: [partnerID, user.uid],
                })
                .then((res) =>
                    openChat(
                        res.id,
                        [partnerID, user.uid],
                        partnerData,
                        partnerID,
                        precastMSGString
                    )
                );
        } else {
            openChat(
                res.chatID,
                [partnerID, user.uid],
                partnerData,
                partnerID,
                precastMSGString
            );
        }
    }

    async function isChatCreated(id) {
        const chatUserArray = [id, user.uid];
        const chatUserArray2 = [user.uid, id];

        const res = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray)
            .get();

        const res2 = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray2)
            .get();

        //console.log(res.empty && res2.empty);

        var resChatId;

        if (!res.empty) {
            res.docs.forEach((doc) => (resChatId = doc.id));
        } else if (!res2.empty) {
            res2.docs.forEach((doc) => (resChatId = doc.id));
        }

        return { result: res.empty && res2.empty, chatID: resChatId };
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Alle WG`s',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
            headerRight: () =>
                isSupport ? (
                    <View>
                        <Icon
                            name="plus"
                            type="simple-line-icon"
                            size={18}
                            color={Colors.primary}
                            iconStyle={{
                                paddingHorizontal: SPACING.MARGIN / 2,
                            }}
                            reverse
                            reverseColor={'white'}
                            onPress={() => navigation.navigate('editApartment')}
                        />
                    </View>
                ) : (
                    <></>
                ),
        });
    }, []);

    useEffect(() => {
        const unsub = db.collection('apartment').onSnapshot((queryRes) =>
            setApartments(
                queryRes.docs.map((docs) => ({
                    id: docs.id,
                    data: docs.data(),
                }))
            )
        );
        db.collection('user')
            .doc(supportUserAccountID)
            .get()
            .then((res) => setSupportUserData(res.data()));

        return unsub;
    }, []);

    function filterApartment(data) {
        if (isSupport) {
            return true;
        } else {
            return data.isPublic;
        }
    }

    return (
        <SafeAreaView edges={['left', 'right']}>
            <ListItem.Swipeable
                rightContent={
                    <Icon
                        name="speech"
                        color={Colors.background}
                        type="simple-line-icon"
                        size={32}
                        containerStyle={{
                            minHeight: '100%',
                            backgroundColor: Colors.primary,
                            justifyContent: 'center',
                        }}
                        onPress={() =>
                            createChat(
                                supportUserID,
                                supportUserData,
                                'Thema:  Neues Projekt anmelden.'
                            )
                        }
                    />
                }
                onPress={() =>
                    createChat(
                        supportUserID,
                        supportUserData,
                        'Thema:  Neues Projekt anmelden.'
                    )
                }
            >
                <Avatar
                    size={36}
                    icon={{
                        name: 'home',
                        type: 'simple-line-icon',
                        color: Colors.background,
                        size: 24,
                    }}
                    containerStyle={{ backgroundColor: Colors.primary }}
                    rounded
                />
                <ListItem.Content>
                    <ListItem.Title>Neues Projekt anmelden</ListItem.Title>
                    <ListItem.Subtitle>
                        Sie haben eine Wohnung, die Sie bereitstellen wollen?
                        Denn melden Sie sich jetzt bei uns!
                    </ListItem.Subtitle>
                </ListItem.Content>
            </ListItem.Swipeable>
            {apartments
                .filter((apartmentData) => filterApartment(apartmentData.data))
                .map(({ id, data }) => (
                    <ListItem
                        key={id}
                        topDivider
                        onPress={() => {
                            openApartmentDetail(id);
                        }}
                    >
                        <Avatar source={{ uri: data.photoURL }} rounded />
                        <ListItem.Content>
                            <ListItem.Title>
                                {data.name}
                                <Text
                                    style={{ fontWeight: '200', fontSize: 16 }}
                                >
                                    {' '}
                                    {data.type === 'location'
                                        ? '(Standort)'
                                        : ''}
                                </Text>
                            </ListItem.Title>
                            <ListItem.Subtitle style={{ fontWeight: '200' }}>
                                {data.adress}
                            </ListItem.Subtitle>
                        </ListItem.Content>
                        {isSupport ? (
                            <Text
                                style={{
                                    fontWeight: '200',

                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {data.isPublic ? 'Öffentlich' : 'Entwurf'}
                            </Text>
                        ) : (
                            <></>
                        )}
                        {isSupport ? (
                            <Icon
                                name="pencil"
                                type="simple-line-icon"
                                color="grey"
                                size={32}
                                onPress={() =>
                                    navigation.navigate('editApartment', {
                                        apartmentID: id,
                                    })
                                }
                            />
                        ) : (
                            <></>
                        )}
                    </ListItem>
                ))}
            <Text
                style={{
                    width: '100%',
                    fontWeight: '100',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: SPACING.MARGIN,
                    textAlign: 'center',
                }}
            >
                Das waren unsere letzten Einträge!
            </Text>
        </SafeAreaView>
    );
}
