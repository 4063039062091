import dateFormat from 'dateformat';
import React, { useEffect, useLayoutEffect } from 'react';
import { useState } from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';
import {
    Button,
    Icon,
    ListItem,
    Avatar,
    Badge,
    SpeedDial,
} from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors } from '../assets/colors/colors';
import { auth, db } from '../firebase';
import { FAB } from 'react-native-elements';

function ChatList({ navigation }) {
    const [chatList, setChatList] = useState([]);
    const [showSD, setShowSD] = useState(false);

    const user = auth.currentUser;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Deine Chats',
            headerStyle: {
                backgroundColor: Colors.primary,
            },

            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {
        const unsub = db
            .collection('msg_rooms')
            .where('users', 'array-contains', user.uid)
            .orderBy('timestamp', 'desc')
            .onSnapshot(async (queryRes) =>
                setChatList(
                    await Promise.all(
                        queryRes.docs.map(async (doc) => {
                            const data = doc.data();
                            var userSnapshot = await db
                                .collection('user')
                                .doc(
                                    data.users.filter(
                                        (data) => data != user.uid
                                    )[0]
                                )
                                .get();
                            var messageSnapshot = await db
                                .collection('msg_rooms')
                                .doc(doc.id)
                                .collection('messages')
                                .orderBy('timestamp', 'asc')
                                .get();

                            var tempData = [];

                            messageSnapshot.forEach((msgDoc) => {
                                tempData = {
                                    id: msgDoc.id,
                                    data: msgDoc.data(),
                                };
                            });

                            if (!tempData) {
                                tempData = {
                                    id: 'ds',
                                    data: null,
                                };
                            }

                            return {
                                id: doc.id,
                                data: data,
                                userData: userSnapshot.data(),
                                userID: userSnapshot.id,
                                messages: tempData,
                            };
                        })
                    )
                )
            );

        return unsub;
    }, []);

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    function timestamptoDate(timestamp) {
        var today = new Date();
        var date = new Date();
        if (timestamp) {
            date = timestamp.toDate();
        }

        var formatDate = dateFormat(date, 'dd.mm.yy');

        if (date.getDate() === today.getDate()) {
            formatDate = dateFormat(date, 'HH:MM');
        } else if (date.getDate() === today.getDate() - 1) {
            formatDate = 'Gestern';
        }

        return formatDate;
    }

    function createChat() {
        navigation.navigate('selectUserForSingleChat');
    }

    function createGroupChat() {
        navigation.navigate('createGroupChat');
    }

    function openChat(chatID, users, partner, partnerID) {
        navigation.navigate('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
        });
    }

    function openGroupChat(chatID, users, groupData) {
        navigation.navigate('groupChat', {
            chatID: chatID,
            users: users,
            groupData: groupData,
        });
    }

    return (
        <SafeAreaView
            edges={['right', 'left']}
            style={{ flex: 1 /* backgroundColor: Colors.background */ }}
        >
            {chatList.map(({ id, data, userData, userID, messages }) =>
                data.type === 'single' ? (
                    <ListItem
                        key={id}
                        topDivider
                        /* containerStyle={{ backgroundColor: Colors.background }} */
                        onPress={() =>
                            openChat(id, data.users, userData, userID)
                        }
                    >
                        <Avatar
                            size={48}
                            rounded
                            title={getInitials(userData.displayName)}
                            containerStyle={{
                                backgroundColor: Colors.primaryDark,
                            }}
                            icon={{
                                name: 'user',
                                type: 'simple-line-icon',
                                color: Colors.background,
                            }}
                            source={{ uri: userData.photoURL }}
                        />
                        <ListItem.Content>
                            <ListItem.Title numberOfLines={1}>
                                {userData.displayName}
                            </ListItem.Title>
                            {messages.id ? (
                                messages.data.type === 'text' ? (
                                    <ListItem.Subtitle
                                        style={styles.message}
                                        numberOfLines={1}
                                    >
                                        {messages.data.message}
                                    </ListItem.Subtitle>
                                ) : (
                                    <ListItem.Subtitle
                                        style={styles.message}
                                        numberOfLines={1}
                                    >
                                        Bild
                                    </ListItem.Subtitle>
                                )
                            ) : (
                                <ListItem.Subtitle
                                    style={styles.message}
                                    numberOfLines={1}
                                >
                                    Noch keine Nachricht
                                </ListItem.Subtitle>
                            )}
                        </ListItem.Content>
                        {messages.id ? (
                            messages.data.seenBy.includes(user.uid) ? (
                                <></>
                            ) : (
                                <Badge
                                    badgeStyle={{
                                        backgroundColor: Colors.accent,
                                    }}
                                    status="warning"
                                />
                            )
                        ) : (
                            <></>
                        )}
                        {messages.id ? (
                            <ListItem.Subtitle
                                style={styles.message}
                                numberOfLines={1}
                            >
                                {timestamptoDate(messages.data.timestamp)}
                            </ListItem.Subtitle>
                        ) : (
                            <ListItem.Subtitle></ListItem.Subtitle>
                        )}
                    </ListItem>
                ) : (
                    <ListItem
                        key={id}
                        topDivider
                        /* containerStyle={{ backgroundColor: Colors.background }} */
                        onPress={() => openGroupChat(id, data.users, data)}
                    >
                        <Avatar
                            size={48}
                            rounded
                            title={getInitials(data.title)}
                            containerStyle={{
                                backgroundColor: Colors.primaryDark,
                            }}
                            source={
                                data.photoURL !== null
                                    ? { uri: data.photoURL }
                                    : null
                            }
                        />
                        {/* {/* console.log(data)} */}
                        <ListItem.Content>
                            <ListItem.Title numberOfLines={1}>
                                {data.title}
                            </ListItem.Title>
                            {messages.id ? (
                                messages.data.type === 'text' ? (
                                    <ListItem.Subtitle
                                        style={styles.message}
                                        numberOfLines={1}
                                    >
                                        {messages.data.message}
                                    </ListItem.Subtitle>
                                ) : (
                                    <ListItem.Subtitle
                                        style={styles.message}
                                        numberOfLines={1}
                                    >
                                        Bild
                                    </ListItem.Subtitle>
                                )
                            ) : (
                                <ListItem.Subtitle
                                    style={styles.message}
                                    numberOfLines={1}
                                >
                                    Noch keine Nachricht
                                </ListItem.Subtitle>
                            )}
                        </ListItem.Content>
                        {messages.id ? (
                            messages.data.seenBy.includes(user.uid) ? (
                                <></>
                            ) : (
                                <Badge
                                    badgeStyle={{
                                        backgroundColor: Colors.accent,
                                    }}
                                    status="warning"
                                />
                            )
                        ) : (
                            <></>
                        )}
                        {messages.id ? (
                            <ListItem.Subtitle
                                style={styles.message}
                                numberOfLines={1}
                            >
                                {timestamptoDate(messages.data.timestamp)}
                            </ListItem.Subtitle>
                        ) : (
                            <ListItem.Subtitle></ListItem.Subtitle>
                        )}
                    </ListItem>
                )
            )}
            <SpeedDial
                isOpen={showSD}
                icon={{ name: 'plus', type: 'simple-line-icon', color: '#fff' }}
                openIcon={{
                    name: 'close',
                    type: 'simple-line-icon',
                    color: '#fff',
                }}
                color={Colors.accent}
                onOpen={() => setShowSD(!showSD)}
                onClose={() => setShowSD(!showSD)}
            >
                <SpeedDial.Action
                    icon={{
                        name: 'user',
                        type: 'simple-line-icon',
                        color: '#fff',
                        size: 20,
                    }}
                    title="Einzelchat"
                    onPress={() => {
                        createChat();
                        setShowSD(false);
                    }}
                    color={Colors.accentDark}
                />
                <SpeedDial.Action
                    icon={{
                        name: 'people',
                        type: 'simple-line-icon',
                        color: '#fff',
                        size: 20,
                    }}
                    color={Colors.accentDark}
                    title="Gruppenchat"
                    onPress={() => {
                        createGroupChat();
                        setShowSD(false);
                    }}
                />
            </SpeedDial>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {},
    header: {},
    message: {
        fontWeight: '300',
    },
});

export default ChatList;
