import React, { useLayoutEffect } from 'react';

import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { Button, Text } from 'react-native-elements';
import { auth, db } from '../firebase';
import { useEffect } from 'react';
import { useState } from 'react';
import { ListItem } from 'react-native-elements';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import { Alert } from 'react-native';
import { Platform } from 'react-native';
import { Linking } from 'react-native';
const appDetail = require('../app.json');

function SettingsScreen({ navigation }) {
    const user = auth.getCurrentUser;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Einstellungen',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {}, []);

    function logOut() {
        if (Platform.OS === 'web') {
            auth.signOut().then(() => {
                navigation.reset({
                    index: 0,
                    routes: [{ name: 'firstup' }],
                });
            });
        } else {
            Alert.alert(
                'Abmelden',
                'Bist du dir sicher, das du dich abmelden möchtest?',
                [
                    {
                        text: 'Abbrechen',
                        onPress: () => {},
                        style: 'destructive',
                    },
                    {
                        text: 'Abmelden',
                        onPress: () =>
                            auth.signOut().then(() => {
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'firstup' }],
                                });
                            }),
                    },
                ],
                { cancelable: true }
            );
        }
    }

    async function openURL(url) {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            await Linking.openURL(url);
        }
    }

    return (
        <SafeAreaView
            edges={['right', 'left']}
            style={{ flex: 1 /* backgroundColor: Colors.background */ }}
        >
            <ListItem
                bottomDivider
                onPress={() => navigation.navigate('editProfile')}
                style={{ marginBottom: SPACING.MARGIN }}
            >
                <Icon name="user" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>Profile bearbeiten</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem bottomDivider>
                <Icon name="speech" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>Was gibts neues</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem bottomDivider style={{ marginBottom: SPACING.MARGIN }}>
                <Icon name="map" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>Roadmap</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem bottomDivider>
                <Icon name="direction" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>AGB</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem
                bottomDivider
                onPress={() => openURL('https://fitwohnen.de/impressum')}
            >
                <Icon name="direction" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>Impressum</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem
                bottomDivider
                onPress={() =>
                    openURL('https://fitwohnen.de/app-datenschutzerklaerung')
                }
                style={{ marginBottom: SPACING.MARGIN }}
            >
                <Icon name="direction" type="simple-line-icon" />
                <ListItem.Content>
                    <ListItem.Title>Datenschutzerklärung</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron name="arrow-right" type="simple-line-icon" />
            </ListItem>
            <ListItem bottomDivider onPress={logOut}>
                <Icon name="power" type="simple-line-icon" color="red" />
                <ListItem.Content>
                    <ListItem.Title>Abmelden</ListItem.Title>
                </ListItem.Content>
            </ListItem>
            <Text
                style={{
                    alignSelf: 'center',
                    fontWeight: '100',
                    paddingVertical: SPACING.MARGIN,
                }}
            >
                {'App Version: ' + appDetail.expo.version}
            </Text>
        </SafeAreaView>
    );
}

export default SettingsScreen;
