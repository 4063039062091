import dateFormat from 'dateformat';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';
import { Platform } from 'react-native';
import { ScrollView, StyleSheet, Text } from 'react-native';
import { Avatar, FAB, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth, db } from '../firebase';
import firebase from 'firebase';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';

function UserDetail({ navigation, route }) {
    const params = route.params;
    const user = auth.currentUser;

    const [userDetail, setUserDetail] = useState([]);

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    function openChat(chatID, users, partner, partnerID) {
        navigation.navigate('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
        });
    }
    async function createChat(partnerID, partnerData) {
        var res = await isChatCreated(partnerID);
        if (res.result) {
            db.collection('msg_rooms')
                .add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'single',
                    users: [partnerID, user.uid],
                })
                .then((res) =>
                    openChat(
                        res.id,
                        [partnerID, user.uid],
                        partnerData,
                        partnerID
                    )
                );
        } else {
            openChat(res.chatID, [partnerID, user.uid], partnerData, partnerID);
        }
    }

    async function isChatCreated(id) {
        const chatUserArray = [id, user.uid];
        const chatUserArray2 = [user.uid, id];

        const res = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray)
            .get();

        const res2 = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray2)
            .get();

        //console.log(res.empty && res2.empty);

        var resChatId;

        if (!res.empty) {
            res.docs.forEach((doc) => (resChatId = doc.id));
        } else if (!res2.empty) {
            res2.docs.forEach((doc) => (resChatId = doc.id));
        }

        return { result: res.empty && res2.empty, chatID: resChatId };
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'User Details',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerBackTitleVisible: false,
            headerShown: false,
        });
    }, []);

    useEffect(() => {
        const unsub = db
            .collection('user')
            .doc(params.userID)
            .onSnapshot((querySnapshot) => setUserDetail(querySnapshot.data()));

        return unsub;
    }, []);

    function timestamptoDate(timestamp) {
        var today = new Date();
        var date = new Date();
        if (timestamp) {
            date = timestamp.toDate();
        }

        var formatDate = dateFormat(date, 'dd.mm.yy');

        if (date.getDate() === today.getDate()) {
            formatDate = dateFormat(date, 'HH:MM');
        } else if (date.getDate() === today.getDate() - 1) {
            formatDate = 'Gestern';
        }

        return formatDate;
    }

    return (
        <SafeAreaView
            edges={['left', 'right', 'top´']}
            style={styles.container}
        >
            <ScrollView
                style={{
                    flex: 1,
                    paddingTop: Platform.OS === 'web' ? SPACING.MARGIN : 0,
                }}
                contentContainerStyle={{
                    flexGrow: 1,
                    alignItems: 'center',
                }}
            >
                <FAB
                    style={{
                        alignSelf: 'flex-start',
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                    color={Colors.accent}
                    icon={{
                        name: 'arrow-left',
                        type: 'simple-line-icon',
                        color: 'white',
                    }}
                    onPress={() => {
                        navigation.goBack();
                    }}
                />

                <Avatar
                    containerStyle={styles.userProfilePicture}
                    source={{ uri: userDetail.photoURL }}
                    title={getInitials(userDetail.displayName)}
                    titleStyle={{ fontSize: 36 }}
                    rounded
                />
                <Text style={styles.title}>{userDetail.displayName}</Text>
                <Text style={styles.subtitle}>{userDetail.email}</Text>
                <View
                    style={{
                        flexDirection: 'row',
                        paddingBottom: SPACING.MARGIN,
                    }}
                >
                    <Icon
                        name="speech"
                        type="simple-line-icon"
                        reverse
                        color={Colors.primary}
                        onPress={() => createChat(params.userID, userDetail)}
                    />
                </View>
                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={styles.listItem}
                    bottomDivider
                >
                    <ListItem.Title style={styles.listItemTitle}>
                        Status:
                    </ListItem.Title>
                    <ListItem.Subtitle style={styles.listItemSubtitle}>
                        {userDetail.status
                            ? userDetail.status
                            : 'Leider noch kein Status angegeben!'}
                    </ListItem.Subtitle>
                </ListItem>
                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={styles.listItem}
                    bottomDivider
                >
                    <ListItem.Title style={styles.listItemTitle}>
                        Wohnort:
                    </ListItem.Title>
                    <ListItem.Subtitle style={styles.listItemSubtitle}>
                        {userDetail.homeTown
                            ? userDetail.homeTown
                            : 'Keine Angaben!'}
                    </ListItem.Subtitle>
                </ListItem>
                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={styles.listItem}
                    bottomDivider
                >
                    <ListItem.Title style={styles.listItemTitle}>
                        Ich suche:
                    </ListItem.Title>
                    <ListItem.Subtitle style={styles.listItemSubtitle}>
                        {userDetail.target
                            ? userDetail.target
                            : 'Keine Angaben'}
                    </ListItem.Subtitle>
                </ListItem>

                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={[
                        styles.listItem,
                        { flexDirection: 'column', alignItems: 'flex-start' },
                    ]}
                    bottomDivider
                >
                    <ListItem.Title
                        style={[
                            styles.listItemTitle,
                            { width: '100%', marginBottom: SPACING.MARGIN / 2 },
                        ]}
                    >
                        Über mich:
                    </ListItem.Title>
                    <ListItem.Subtitle style={styles.listItemSubtitle}>
                        {userDetail.about ? userDetail.about : 'Keine Angaben'}
                    </ListItem.Subtitle>
                </ListItem>
                <ListItem
                    style={{ width: '100%' }}
                    containerStyle={[
                        styles.listItem,
                        { marginTop: SPACING.MARGIN / 2 },
                    ]}
                    bottomDivider
                >
                    <ListItem.Title style={styles.listItemTitle}>
                        Registriert seid:
                    </ListItem.Title>
                    <ListItem.Subtitle style={styles.listItemSubtitle}>
                        {timestamptoDate(userDetail.registerTime)}
                    </ListItem.Subtitle>
                </ListItem>
                {userDetail.showAdvancedInformation ? (
                    <>
                        <Text
                            style={{
                                marginTop: SPACING.MARGIN,
                                marginBottom: SPACING.MARGIN / 3,
                                alignSelf: 'flex-start',
                                paddingHorizontal: SPACING.MARGIN,
                                fontSize: 16,
                                fontWeight: 'bold',
                            }}
                        >
                            Weitere Information:
                        </Text>
                        <ListItem
                            style={{ width: '100%' }}
                            containerStyle={styles.listItem}
                            bottomDivider
                        >
                            <ListItem.Title style={styles.listItemTitle}>
                                Wunschheimatort:
                            </ListItem.Title>
                            <ListItem.Subtitle style={styles.listItemSubtitle}>
                                {userDetail.favHomeTown
                                    ? userDetail.favHomeTown
                                    : 'Keine Angaben'}
                            </ListItem.Subtitle>
                        </ListItem>
                        <ListItem
                            style={{ width: '100%' }}
                            containerStyle={styles.listItem}
                            bottomDivider
                        >
                            <ListItem.Title style={styles.listItemTitle}>
                                Mobil mit:
                            </ListItem.Title>
                            <ListItem.Subtitle style={styles.listItemSubtitle}>
                                {userDetail.locomotion
                                    ? userDetail.locomotion
                                    : 'Keine Angaben'}
                            </ListItem.Subtitle>
                        </ListItem>
                        <ListItem
                            style={{ width: '100%' }}
                            containerStyle={styles.listItem}
                            bottomDivider
                        >
                            <ListItem.Title style={styles.listItemTitle}>
                                Handicap:
                            </ListItem.Title>
                            <ListItem.Subtitle style={styles.listItemSubtitle}>
                                {userDetail.handicap
                                    ? userDetail.handicap
                                    : 'Keine Angaben'}
                            </ListItem.Subtitle>
                        </ListItem>
                        <ListItem
                            style={{ width: '100%' }}
                            containerStyle={styles.listItem}
                            bottomDivider
                        >
                            <ListItem.Title style={styles.listItemTitle}>
                                Lieblingsausflugsziel:
                            </ListItem.Title>
                            <ListItem.Subtitle style={styles.listItemSubtitle}>
                                {userDetail.favDestination
                                    ? userDetail.favDestination
                                    : 'Keine Angaben'}
                            </ListItem.Subtitle>
                        </ListItem>
                        <ListItem
                            style={{ width: '100%' }}
                            containerStyle={styles.listItem}
                            bottomDivider
                        >
                            <ListItem.Title style={styles.listItemTitle}>
                                Hobbys:
                            </ListItem.Title>
                            <ListItem.Subtitle style={styles.listItemSubtitle}>
                                {userDetail.hobbys
                                    ? userDetail.hobbys
                                    : 'Keine Angaben'}
                            </ListItem.Subtitle>
                        </ListItem>
                    </>
                ) : (
                    <></>
                )}
                {userDetail.geolocation ? (
                    <View style={styles.mapContainer}>
                        <MapView
                            style={{ flex: 1 }}
                            region={
                                userDetail.geolocation
                                    ? {
                                          'latitude':
                                              userDetail.geolocation.latitude,
                                          'longitude':
                                              userDetail.geolocation.longitude,
                                          'latitudeDelta': 1.5,
                                          'longitudeDelta': 1.5,
                                      }
                                    : {
                                          'latitude': 0,
                                          'longitude': 0,
                                          'latitudeDelta': 0,
                                          'longitudeDelta': 0,
                                      }
                            }
                            provider={PROVIDER_GOOGLE}
                        >
                            {Platform.OS !== 'web' ? (
                                <>
                                    {userDetail.geolocation ? (
                                        <Marker
                                            coordinate={{
                                                'latitude':
                                                    userDetail.geolocation
                                                        .latitude,
                                                'longitude':
                                                    userDetail.geolocation
                                                        .longitude,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <>
                                    {userDetail.geolocation ? (
                                        <MapView.Marker
                                            coordinate={{
                                                'latitude':
                                                    userDetail.geolocation
                                                        .latitude,
                                                'longitude':
                                                    userDetail.geolocation
                                                        .longitude,
                                            }}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </MapView>
                    </View>
                ) : (
                    <></>
                )}
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    userProfilePicture: {
        backgroundColor: 'grey',
        height: SPACING.MARGIN * 8,
        width: SPACING.MARGIN * 8,
        marginTop: SPACING.MARGIN * 2,
    },
    title: {
        fontWeight: '500',
        fontSize: 18,
        marginTop: SPACING.MARGIN,
        marginBottom: SPACING.MARGIN / 4,
    },
    subtitle: {
        fontWeight: '300',
        fontSize: 14,
        marginBottom: SPACING.MARGIN / 2,
    },
    listItem: {
        justifyContent: 'space-between',
        paddingHorizontal: SPACING.MARGIN * 1,
    },
    listItemTitle: {
        fontSize: 16,
        fontWeight: '500',
    },
    listItemSubtitle: {
        fontSize: 14,
        fontWeight: '300',
    },
    mapContainer: {
        backgroundColor: 'lightgrey',
        width: '90%',
        height: SPACING.MARGIN * 15,
        marginVertical: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        overflow: 'hidden',
    },
});

export default UserDetail;
