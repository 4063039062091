import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { db } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { Text } from 'react-native';
import firebase from 'firebase';

export default function AddUserToGroup({ navigation, route }) {
    const [allUser, setAllUser] = useState([]);

    const params = route.params;

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'WG Auswählen',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {
        const unsub = db
            .collection('user')
            .where('visible', '!=', 'none')
            .onSnapshot((queryRes) =>
                setAllUser(
                    queryRes.docs.map((docs) => ({
                        id: docs.id,
                        data: docs.data(),
                    }))
                )
            );

        return unsub;
    }, []);

    function selectApartment(users, id) {
        db.collection('msg_rooms')
            .doc(params.groupID)
            .update({
                users: [...users, id],
                tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => navigation.goBack());
    }

    function removeSelection() {
        /*db.collection('msg_rooms')
            .doc(params.groupID)
            .update({
                selectedApartment: null,
                tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => navigation.goBack());*/
        alert('Du kannst noch keine Nutzer entfernen!');
    }

    return (
        <SafeAreaView edges={['left', 'right']}>
            {allUser.map(({ id, data }) => (
                <ListItem
                    key={id}
                    topDivider
                    onPress={() => {
                        !params.user.includes(id)
                            ? selectApartment(params.user, id)
                            : removeSelection();
                    }}
                >
                    <Avatar
                        source={{ uri: data.photoURL }}
                        containerStyle={{ backgroundColor: 'grey' }}
                        title={getInitials(data.displayName)}
                        rounded
                        size={SPACING.MARGIN * 2}
                    />
                    <ListItem.Content style={{ flex: 1 }}>
                        <ListItem.Title>{data.displayName}</ListItem.Title>
                        <ListItem.Subtitle style={{ fontWeight: '200' }}>
                            {data.email}
                        </ListItem.Subtitle>
                    </ListItem.Content>
                    {params.user.includes(id) ? (
                        <Icon
                            name="check"
                            type="simple-line-icon"
                            color="green"
                        />
                    ) : (
                        <></>
                    )}
                </ListItem>
            ))}
            <Text
                style={{
                    width: '100%',
                    fontWeight: '100',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: SPACING.MARGIN,
                    textAlign: 'center',
                }}
            >
                Das waren unsere letzten Einträge!
            </Text>
        </SafeAreaView>
    );
}
