import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { auth, db } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { Text } from 'react-native';
import firebase from 'firebase';

export default function SelectUserForSingleChat({ navigation }) {
    const [allUser, setAllUser] = useState([]);

    const user = auth.currentUser;

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'WG Auswählen',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {
        const unsub = db
            .collection('user')
            .where('visible', '!=', 'none')
            .onSnapshot((queryRes) => {
                setAllUser(
                    queryRes.docs
                        .filter((doc) => doc.id !== user.uid)
                        .map((docs) => ({
                            id: docs.id,
                            data: docs.data(),
                        }))
                );
                updateUserList();
            });

        return unsub;
    }, []);

    useEffect(() => {
        updateUserList();
    });

    function updateUserList() {
        if (allUser !== []) {
            var tempUserList = [];
            allUser
                .filter((userFilter, index) => {
                    if (userFilter.id !== user.uid) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .forEach(async ({ id }, index) => {
                    var resData = await isChatCreated(id);
                    //console.log(result);
                    var tempUser = {
                        ...allUser[index],
                        data: {
                            ...allUser[index].data,
                            chatCreated: !resData.result,
                            chatID: resData.chatID,
                        },
                    };

                    tempUserList.push(tempUser);

                    if (tempUserList.length === allUser.length) {
                        if (
                            JSON.stringify(tempUserList) !=
                            JSON.stringify(allUser)
                        ) {
                            setAllUser(tempUserList);
                        }
                    }
                });
        }
    }

    function openChat(chatID, users, partner, partnerID) {
        navigation.replace('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
        });
    }

    async function createChat(partnerID, partnerData) {
        var res = await isChatCreated(partnerID);
        if (res.result) {
            db.collection('msg_rooms')
                .add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'single',
                    users: [partnerID, user.uid],
                })
                .then((res) =>
                    openChat(
                        res.id,
                        [partnerID, user.uid],
                        partnerData,
                        partnerID
                    )
                );
        } else {
            openChat(res.chatID, [partnerID, user.uid], partnerData, partnerID);
        }
    }

    async function isChatCreated(id) {
        const chatUserArray = [id, user.uid];
        const chatUserArray2 = [user.uid, id];

        const res = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray)
            .get();

        const res2 = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray2)
            .get();

        var resChatId;

        if (!res.empty) {
            res.docs.forEach((doc) => (resChatId = doc.id));
        } else if (!res2.empty) {
            res2.docs.forEach((doc) => (resChatId = doc.id));
        }

        return { result: res.empty && res2.empty, chatID: resChatId };
    }

    return (
        <SafeAreaView edges={['left', 'right']}>
            {allUser.map(({ id, data }) => (
                <ListItem
                    key={id}
                    onPress={() => createChat(id, data)}
                    topDivider
                >
                    <Avatar
                        source={{ uri: data.photoURL }}
                        containerStyle={{ backgroundColor: 'grey' }}
                        title={getInitials(data.displayName)}
                        rounded
                        size={SPACING.MARGIN * 2}
                    />
                    <ListItem.Content style={{ flex: 1 }}>
                        <ListItem.Title>{data.displayName}</ListItem.Title>
                        <ListItem.Subtitle style={{ fontWeight: '200' }}>
                            {data.email}
                        </ListItem.Subtitle>
                    </ListItem.Content>
                    <Icon
                        name={!data.chatCreated ? 'user-follow' : 'speech'}
                        type="simple-line-icon"
                        color="grey"
                    />
                </ListItem>
            ))}
            <Text
                style={{
                    width: '100%',
                    fontWeight: '100',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: SPACING.MARGIN,
                    textAlign: 'center',
                }}
            >
                Das waren unsere letzten Einträge!
            </Text>
        </SafeAreaView>
    );
}
