import React, { useEffect, useLayoutEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { Image } from 'react-native-elements';
import { Text, View } from 'react-native';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth } from '../firebase';

function LoadinScreenStart({ navigation }) {
    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Fit Wohnen',
            headerShown: true,
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                navigation.replace('home');
            } else {
                navigation.replace('firstup');
            }
        });

        return unsubscribe;
    }, []);

    return (
        <View
            style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
            <Image
                source={require('../assets/Logo_fitwohnen.png')}
                containerStyle={{
                    width: '80%',
                    height: '25%',
                    marginBottom: SPACING.MARGIN,
                }}
                resizeMode="contain"
            />
            <ActivityIndicator size="large" />
        </View>
    );
}

export default LoadinScreenStart;
