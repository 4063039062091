import React, { useLayoutEffect, useState } from 'react';
import {
    KeyboardAvoidingView,
    StyleSheet,
    Text,
    TextInput,
    TouchableHighlight,
    TouchableOpacity,
    View,
} from 'react-native';
import {
    SafeAreaView,
    withSafeAreaInsets,
} from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors/colors';
import { SimpleLineIcons } from '@expo/vector-icons';
import { auth, db } from '../../firebase';
import firebase from 'firebase';
import { Input } from 'react-native-elements';
import Icon from 'react-native-vector-icons/SimpleLineIcons';
import { Platform } from 'react-native';

function Register({ navigation }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [displayName, setDisplayName] = useState('');

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Dashboard',
            headerShown: false,
        });
    }, []);

    function submitRegister(params) {
        if (
            email !== '' &&
            displayName !== '' &&
            password !== '' &&
            repeatPassword !== ''
        ) {
            if (repeatPassword === password) {
                auth.createUserWithEmailAndPassword(email, password)
                    .then((authUser) => {
                        const user = auth.currentUser;

                        db.collection('user').doc(user.uid).set({
                            displayName: displayName,
                            email: user.email,
                            visible: 'none',
                            registerTime:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            photoURL: null,
                            acceptMSGWithOutFriends: true,
                        });

                        db.collection('msg_rooms').add({
                            timestamp:
                                firebase.firestore.FieldValue.serverTimestamp(),
                            type: 'single',
                            users: [user.uid, 'MVhowIOc7wasftJZt2bFJbeZXtq2'],
                        });

                        authUser.user
                            .updateProfile({
                                displayName: displayName,
                            })
                            .then(() => {
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'home' }],
                                });
                            })
                            .catch((error) => {
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'home' }],
                                });
                            });

                        authUser.user.sendEmailVerification().then(() => {
                            alert('Bitte E-Mail verifizieren!');
                        });
                    })
                    .catch((error) => alert(error));
            } else {
                alert('Dein Password ist nicht gleich!');
            }
        } else {
            alert('Es sind nicht alle Felder richtig ausgefüllt');
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <Text style={styles.title}>Jetzt Registrieren</Text>
            <Input
                leftIcon={<Icon name="user" size={22} color="grey" />}
                leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                containerStyle={{ width: '90%' }}
                inputContainerStyle={styles.inputContainer}
                inputStyle={{ fontSize: 18 }}
                autoCapitalize="words"
                onChangeText={(event) => setDisplayName(event)}
                textContentType="name"
                autoCompleteType="name"
                keyboardType="default"
                placeholder="Dein Name"
            />
            <Input
                leftIcon={<Icon name="envelope" size={22} color="grey" />}
                leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                containerStyle={{ width: '90%' }}
                inputContainerStyle={styles.inputContainer}
                inputStyle={{ fontSize: 18 }}
                onChangeText={(event) => setEmail(event)}
                textContentType="emailAddress"
                autoCompleteType="email"
                keyboardType="email-address"
                placeholder="Deine E-Mail"
            />
            <KeyboardAvoidingView
                style={{ width: '100%', alignItems: 'center' }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            >
                <Input
                    leftIcon={<Icon name="lock" size={22} color="grey" />}
                    leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    containerStyle={{ width: '90%', marginTop: SPACING.MARGIN }}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={{ fontSize: 18 }}
                    secureTextEntry
                    onChangeText={(event) => setPassword(event)}
                    textContentType="password"
                    autoCompleteType="password"
                    keyboardType="default"
                    placeholder="Dein Password"
                />
                <Input
                    leftIcon={<Icon name="lock" size={22} color="grey" />}
                    leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    containerStyle={{ width: '90%' }}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={{ fontSize: 18 }}
                    secureTextEntry
                    onSubmitEditing={(event) => {
                        submitRegister();
                    }}
                    secureTextEntry
                    onChangeText={(event) => setRepeatPassword(event)}
                    textContentType="password"
                    autoCompleteType="password"
                    keyboardType="default"
                    placeholder="Password wiederholen"
                />
            </KeyboardAvoidingView>
            <TouchableHighlight
                underlayColor={Colors.accentDark}
                style={styles.submitContainer}
                onPress={() => submitRegister()}
            >
                <Text style={styles.submitText}>Weiter</Text>
            </TouchableHighlight>
            <TouchableOpacity
                style={styles.backBTNContainer}
                onPress={() => navigation.goBack()}
            >
                <Text style={styles.backBTNText}>Zurück</Text>
            </TouchableOpacity>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        backgroundColor: Colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 48,
        color: 'white',
        fontWeight: '900',
        width: '90%',
        marginBottom: SPACING.MARGIN * 2,
    },
    inputContainer: {
        padding: SPACING.MARGIN / 3,
        paddingHorizontal: SPACING.MARGIN,
        backgroundColor: Colors.background,
        borderRadius: SPACING.MARGIN,
    },
    submitContainer: {
        backgroundColor: Colors.accent,
        width: '90%',
        alignItems: 'center',
        padding: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        marginTop: SPACING.MARGIN,
    },
    submitText: {
        color: 'white',
        fontSize: 24,
        fontWeight: 'normal',
    },
    backBTNContainer: {
        width: '90%',
        paddingVertical: SPACING.MARGIN,
        paddingHorizontal: SPACING.MARGIN / 2,
    },
    backBTNText: {
        color: 'white',
        textDecorationLine: 'underline',
        textDecorationColor: 'white',
    },
});

export default Register;
