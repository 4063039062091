import React, { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import {
    Avatar,
    Button,
    ButtonGroup,
    Icon,
    Input,
    Text,
} from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth, db, storage } from '../firebase';
import * as ImagePicker from 'expo-image-picker';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native';
import { Popable } from 'react-native-popable';
import * as Location from 'expo-location';
import firebase from 'firebase';

function EditProfile({ navigation }) {
    const user = auth.currentUser;

    const [userDetail, setUserDetail] = useState([]);

    const infoButtons = ['Nicht Sichtbar', 'Sichtbar'];

    const visibilityButtons = ['Nicht Sichtbar', 'Liste', 'Karte + Liste'];
    const visibilityButtonValue = ['none', 'partial', 'all'];
    const [selectedButton, setSelectedButton] = useState(
        visibilityButtonValue.indexOf(userDetail.visible)
    );
    const infoButtonValue = [false, true];
    const [selectedInfoButton, setSelectedInfoButton] = useState(
        infoButtonValue.indexOf(userDetail.showAdvancedInformation)
    );
    const [image, setImage] = useState(null);

    const [displayName, setDisplayName] = useState('');
    const [status, setStatus] = useState('');
    const [about, setAbout] = useState('');
    const [target, setTarget] = useState('');
    const [favHomeTown, setFavHomeTown] = useState('');
    const [locomotion, setLocomotion] = useState('');
    const [handicap, setHandicap] = useState('');
    const [favDestination, setFavDestination] = useState('');
    const [hobbys, setHobbys] = useState('');
    const [homeTown, setHomeTown] = useState('');

    var initials = 'FW';

    if (user.displayName) {
        if (user.displayName.trim().indexOf(' ') != -1) {
            const fullName = user.displayName.split(' ');
            initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            initials = initials.toUpperCase();
        } else {
            initials = user.displayName.charAt(0);
        }
    }

    function setVisibility(event) {
        db.collection('user').doc(user.uid).update({
            visible: visibilityButtonValue[event],
            visibilityEdited: true,
        });
        setSelectedButton(event);
    }

    function setInfo(event) {
        db.collection('user').doc(user.uid).update({
            showAdvancedInformation: infoButtonValue[event],
            profileEdited: true,
        });
        setSelectedInfoButton(event);
    }

    async function getGeoCode(place) {
        try {
            Location.setGoogleApiKey('AIzaSyCOfwdT1MFOnRCS2_AhMLONjbToRwQrJ9I');
            let result = await Location.geocodeAsync(place);
            db.collection('user')
                .doc(user.uid)
                .update({
                    geolocation: new firebase.firestore.GeoPoint(
                        result[0].latitude,
                        result[0].longitude
                    ),
                });
        } catch (e) {
            console.log(e);
        } finally {
        }
    }

    /*console.log(
        visibilityButtonValue.indexOf(userDetail.visible) +
            '  ' +
            infoButtonValue.indexOf(userDetail.showAdvancedInformation)
    ); */

    function updateProfile() {
        if (displayName !== '') {
            db.collection('user').doc(user.uid).update({
                displayName: displayName,
                profileEdited: true,
            });
            user.updateProfile({
                displayName: displayName,
            });
            setDisplayName('');
        }
        if (status !== '') {
            db.collection('user').doc(user.uid).update({
                status: status,
                profileEdited: true,
            });
            setStatus('');
        }
        if (about !== '') {
            db.collection('user').doc(user.uid).update({
                about: about,
                profileEdited: true,
            });
            setAbout('');
        }
        if (target !== '') {
            db.collection('user').doc(user.uid).update({
                target: target,
                profileEdited: true,
            });
            setTarget('');
        }
        if (favHomeTown !== '') {
            db.collection('user').doc(user.uid).update({
                favHomeTown: favHomeTown,
                profileEdited: true,
            });
            setFavHomeTown('');
        }
        if (locomotion !== '') {
            db.collection('user').doc(user.uid).update({
                locomotion: locomotion,
                profileEdited: true,
            });
            setLocomotion('');
        }
        if (handicap !== '') {
            db.collection('user').doc(user.uid).update({
                handicap: handicap,
                profileEdited: true,
            });
            setHandicap('');
        }
        if (favDestination !== '') {
            db.collection('user').doc(user.uid).update({
                favDestination: favDestination,
                profileEdited: true,
            });
            setFavDestination('');
        }
        if (hobbys !== '') {
            db.collection('user').doc(user.uid).update({
                hobbys: hobbys,
                profileEdited: true,
            });
            setHobbys('');
        }
        if (homeTown !== '') {
            getGeoCode(homeTown);

            db.collection('user').doc(user.uid).update({
                homeTown: homeTown,
                profileEdited: true,
            });
            setHomeTown('');
        }
    }

    useEffect(() => {
        const unsub = db
            .collection('user')
            .doc(user.uid)
            .onSnapshot((queryRes) => {
                setUserDetail(queryRes.data());
                setSelectedButton(
                    visibilityButtonValue.indexOf(queryRes.data().visible)
                );
            });

        return unsub;
    }, []);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Profil bearbeiten',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.cancelled) {
            setImage(result.uri);
            const storageRef = storage.ref();
            var imagesRef = storageRef.child('pb/' + user.uid + '.jpg');
            var response = await fetch(result.uri);
            const blob = await response.blob();
            await imagesRef.put(blob);

            const photoURL = await imagesRef.getDownloadURL();

            await db.collection('user').doc(user.uid).update({
                photoURL: photoURL,
            });

            const snapshot = await db
                .collectionGroup('messages')
                .where('senderID', '==', user.uid)
                .get();
            snapshot.docs.forEach((snapshot) => {
                snapshot.ref.update({
                    senderdata: userDetail,
                });
                //console.log(snapshot.id);
            });

            user.updateProfile({
                photoURL: photoURL,
            });
        }
    };

    function resetChanges() {
        setDisplayName('');
        setStatus('');
        setTarget('');
        setHomeTown('');
        setAbout('');
        setFavHomeTown('');
        setLocomotion('');
        setHandicap('');
        setFavDestination('');
        setHobbys('');
    }

    return (
        <SafeAreaView edges={['left', 'right']} style={styles.container}>
            <ScrollView
                contentContainerStyle={{
                    paddingHorizontal: SPACING.MARGIN,
                    paddingVertical: SPACING.MARGIN,
                    alignItems: 'center',
                }}
                style={{ flex: 1, width: '100%' }}
            >
                <Avatar
                    rounded
                    title={initials}
                    source={{ uri: userDetail.photoURL }}
                    containerStyle={styles.headerImage}
                    titleStyle={{ fontSize: 32 }}
                >
                    <Icon
                        containerStyle={styles.uploadProfilePicture}
                        size={SPACING.MARGIN / 1.5}
                        name="cursor"
                        type="simple-line-icon"
                        rounded
                        reverse
                        color={Colors.accent}
                        reverseColor={Colors.background}
                        onPress={pickImage}
                    />
                </Avatar>
                <Text style={styles.label}>Dein Anzeigename</Text>
                <Input
                    value={displayName}
                    placeholder={user.displayName}
                    onChangeText={setDisplayName}
                    autoCapitalize="words"
                    leftIcon={{ name: 'user', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Dein Name der anderen Nutzern angezeigt wird."
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Dein Status</Text>
                <Input
                    value={status}
                    placeholder={
                        userDetail.status
                            ? userDetail.status
                            : 'Noch kein Status angegeben!'
                    }
                    autoCapitalize="sentences"
                    leftIcon={{ name: 'notebook', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setStatus}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Setze dein Status ganz nach belieben."
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Dein Heimatort</Text>
                <Input
                    value={homeTown}
                    placeholder={
                        userDetail.homeTown
                            ? userDetail.homeTown
                            : 'Keine Angaben'
                    }
                    autoCapitalize="sentences"
                    leftIcon={{ name: 'home', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setHomeTown}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Der Ort in dem du wohnst."
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Wonach suchst du</Text>
                <Input
                    value={target}
                    placeholder={
                        userDetail.target ? userDetail.target : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'user-follow', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setTarget}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="einer Wohngemeinschaft, einem Mitbewohner, Mitstreitern, neuen Kontakten"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />

                {/* <Text style={styles.label}>Dein Wohnort</Text>
                <Input
                    placeholder={userDetail.email}
                    autoCapitalize="words"
                    leftIcon={{ name: 'user', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                /> */}
                <Text style={styles.label}>Deine Sichtbarkeit</Text>
                {/**/}
                <ButtonGroup
                    selectedIndex={selectedButton}
                    onPress={(event) => setVisibility(event)}
                    buttons={visibilityButtons}
                    containerStyle={styles.multipleSelectContainer}
                    selectedButtonStyle={styles.multipleSelectActive}
                />
                <Text style={styles.label}>Über dich</Text>
                <Input
                    value={about}
                    placeholder={
                        userDetail.about ? userDetail.about : 'Keine Angaben'
                    }
                    multiline
                    numberOfLines={1}
                    clearButtonMode="while-editing"
                    containerStyle={styles.multilineContainer}
                    inputContainerStyle={styles.multilineInputContainer}
                    onChangeText={setAbout}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Erzähl etwas über dich, wenn dir etwas wichtiges für deine Mitmenschen auf dem Herzen liegt…"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Zeige alle Informationen</Text>
                <ButtonGroup
                    selectedIndex={infoButtonValue.indexOf(
                        userDetail.showAdvancedInformation
                    )}
                    onPress={(event) => setInfo(event)}
                    buttons={infoButtons}
                    containerStyle={styles.multipleSelectContainer}
                    selectedButtonStyle={styles.multipleSelectActive}
                />
                <Text style={styles.label}>Dein Wunschheimatort</Text>
                <Input
                    value={favHomeTown}
                    placeholder={
                        userDetail.favHomeTown
                            ? userDetail.favHomeTown
                            : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'user', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setFavHomeTown}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Wo würdest du gerne Wohnen?"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Wie bist du mobil</Text>
                <Input
                    value={locomotion}
                    placeholder={
                        userDetail.locomotion
                            ? userDetail.locomotion
                            : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'direction', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setLocomotion}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Rollstuhl, Rollator, Gehstock, Sonstige Gehhilfen, Ohne Gehhilfen"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Wie ist dein Handicap</Text>
                <Input
                    value={handicap}
                    placeholder={
                        userDetail.handicap
                            ? userDetail.handicap
                            : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'tag', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setHandicap}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Körperlich, Geistig oder Psychisch"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Dein Lieblingsausflugziel</Text>
                <Input
                    value={favDestination}
                    placeholder={
                        userDetail.favDestination
                            ? userDetail.favDestination
                            : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'direction', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setFavDestination}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Wenn ich einen Ausflug starte, dann: (ins Konzert, ins Fußballstadion, in die Stadt, in den Park, ins Restaurant, ins Museum)"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <Text style={styles.label}>Deine Hobbys</Text>
                <Input
                    value={hobbys}
                    placeholder={
                        userDetail.hobbys ? userDetail.hobbys : 'Keine Angabe'
                    }
                    leftIcon={{ name: 'tag', type: 'simple-line-icon' }}
                    clearButtonMode="while-editing"
                    containerStyle={styles.inputContainer}
                    inputContainerStyle={styles.textInputContainer}
                    onChangeText={setHobbys}
                    leftIconContainerStyle={styles.textIconContainer}
                    rightIcon={
                        <Popable
                            style={styles.popableContainer}
                            content="Natur & Pflanzen, Internet und Soziale Netzwerke, Shopping, Youtube / Netflix & co., Rätsel, Bücher und Lesen, Essen gehen, Gaming, Musik, Basteln, Gesellschaftsspiele, Sport"
                        >
                            <Icon
                                name="question"
                                type="simple-line-icon"
                                color="grey"
                            />
                        </Popable>
                    }
                />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginTop: SPACING.MARGIN * 1.5,
                    }}
                >
                    <Button
                        containerStyle={{
                            alignSelf: 'flex-end',
                        }}
                        buttonStyle={{
                            padding: SPACING.MARGIN / 2,
                            borderRadius: SPACING.MARGIN,
                            paddingHorizontal: SPACING.MARGIN,
                            borderColor: Colors.primary,
                            borderWidth: 2,
                        }}
                        titleStyle={{
                            color: Colors.primary,
                        }}
                        type="outline"
                        title="Rückgängig"
                        onPress={resetChanges}
                        disabled={
                            about !== '' ||
                            status !== '' ||
                            displayName !== '' ||
                            target !== '' ||
                            homeTown !== '' ||
                            target !== '' ||
                            favHomeTown !== '' ||
                            locomotion !== '' ||
                            handicap !== '' ||
                            favDestination !== '' ||
                            hobbys !== ''
                                ? false
                                : true
                        }
                    />
                    <Button
                        containerStyle={{
                            alignSelf: 'flex-end',
                        }}
                        buttonStyle={{
                            padding: SPACING.MARGIN / 2,
                            borderRadius: SPACING.MARGIN,
                            paddingHorizontal: SPACING.MARGIN,
                        }}
                        title="Speichern"
                        onPress={updateProfile}
                        disabled={
                            about !== '' ||
                            status !== '' ||
                            displayName !== '' ||
                            target !== '' ||
                            homeTown !== '' ||
                            target !== '' ||
                            favHomeTown !== '' ||
                            locomotion !== '' ||
                            handicap !== '' ||
                            favDestination !== '' ||
                            hobbys !== ''
                                ? false
                                : true
                        }
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    label: { textAlign: 'left', width: '100%' },
    headerImage: {
        width: SPACING.USER_IMAGE,
        height: SPACING.USER_IMAGE,
        backgroundColor: 'grey',
        marginBottom: SPACING.MARGIN * 2,
        height: SPACING.MARGIN * 6,
        width: SPACING.MARGIN * 6,
    },
    popableContainer: {
        //width: '25%',
    },
    inputContainer: {
        width: '100%',
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        height: SPACING.MARGIN * 2,
        borderRadius: SPACING.MARGIN,
        marginBottom: SPACING.MARGIN,
        marginTop: SPACING.MARGIN / 2,
    },
    textInputContainer: {
        margin: 0,
        padding: 0,
        borderBottomWidth: 0,
        paddingHorizontal: SPACING.MARGIN / 2,
        paddingLeft: 0,
    },
    textIconContainer: {
        marginHorizontal: SPACING.MARGIN / 4,
    },
    multilineContainer: {
        width: '100%',
        padding: 0,
        margin: 0,
        marginTop: SPACING.MARGIN / 2,
        marginBottom: SPACING.MARGIN,
        paddingBottom: 0,

        backgroundColor: 'white',
        borderRadius: SPACING.MARGIN,
    },
    multilineInputContainer: {
        borderBottomWidth: 0,
        backgroundColor: 'white',
        width: '100%',
        padding: SPACING.MARGIN / 2,
        marginBottom: 0,
        paddingBottom: 0,
    },
    multipleSelectContainer: {
        backgroundColor: 'white',
        width: '100%',
        borderRadius: SPACING.MARGIN / 2,
        marginBottom: SPACING.MARGIN,
    },
    multipleSelectActive: {
        backgroundColor: Colors.accent,
    },
    uploadProfilePicture: {
        position: 'absolute',
        bottom: -SPACING.MARGIN / 1.75,
        right: -SPACING.MARGIN / 1.75,
    },
});

export default EditProfile;
