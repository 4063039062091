import React from 'react';
import { useEffect } from 'react';

import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { useLayoutEffect } from 'react';
import { Button, Icon, Input, Text } from 'react-native-elements';
import { ActivityIndicatorBase, StyleSheet } from 'react-native';
import { Avatar } from 'react-native-elements';
import { Popable } from 'react-native-popable';
import { useState } from 'react';
import { View } from 'react-native';
import { auth, db, storage } from '../firebase';
import firebase from 'firebase';

import * as ImagePicker from 'expo-image-picker';

function CreateGroupChat({ navigation }) {
    const [displayName, setDisplayName] = useState('');
    const [image, setImage] = useState(null);
    const [displayNameError, setDisplayNameError] = useState(false);

    const user = auth.currentUser;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Gruppen Chat erstellen',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    async function createGroup() {
        if (displayName !== '') {
            const docRef = await db.collection('msg_rooms').add({
                photoURL: image,
                title: displayName,
                type: 'group',
                users: [user.uid],
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });

            const storageRef = storage.ref();
            var imagesRef = storageRef.child('pb/' + docRef.id + '.jpg');
            var response = await fetch(image);
            const blob = await response.blob();
            await imagesRef.put(blob);
            const photoURL = await imagesRef.getDownloadURL();

            db.collection('msg_rooms').doc(docRef.id).update({
                photoURL: photoURL,
            });

            navigation.replace('groupChat', {
                chatID: docRef.id,
                users: [user.uid],
                groupData: {
                    photoURL: photoURL,
                    title: displayName,
                    type: 'group',
                    users: [user.uid],
                },
            });
        } else {
            setDisplayNameError(true);
        }
    }
    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
        });

        if (!result.cancelled) {
            setImage(result.uri);
        }
    };
    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    return (
        <SafeAreaView edges={['left', 'right']} style={styles.container}>
            <Avatar
                rounded
                title={getInitials(displayName)}
                containerStyle={styles.headerImage}
                titleStyle={{ fontSize: 32 }}
                source={{ uri: image }}
            >
                {
                    <Icon
                        containerStyle={styles.uploadProfilePicture}
                        size={SPACING.MARGIN / 1.5}
                        name="cursor"
                        type="simple-line-icon"
                        rounded
                        reverse
                        color={Colors.accent}
                        reverseColor={Colors.background}
                        onPress={pickImage}
                    />
                }
            </Avatar>
            <Text style={styles.label}>Dein Anzeigename</Text>
            <Input
                value={displayName}
                placeholder={'Gruppenname'}
                onChangeText={setDisplayName}
                autoCapitalize="words"
                leftIcon={{ name: 'user', type: 'simple-line-icon' }}
                clearButtonMode="while-editing"
                containerStyle={[
                    styles.inputContainer,
                    { borderWidth: displayNameError ? 1 : 0 },
                ]}
                inputContainerStyle={styles.textInputContainer}
                leftIconContainerStyle={styles.textIconContainer}
                onSubmitEditing={createGroup}
                rightIcon={
                    <Popable
                        style={styles.popableContainer}
                        content="Der Name der Gruppe."
                    >
                        <Icon
                            name="question"
                            type="simple-line-icon"
                            color="grey"
                        />
                    </Popable>
                }
            />
            <View style={styles.buttonBar}>
                <Button
                    title="Gruppe erstellen"
                    containerStyle={styles.buttonContainer}
                    buttonStyle={styles.buttonStyle}
                    onPress={createGroup}
                />
            </View>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: { flex: 1, alignItems: 'center', justifyContent: 'center' },
    label: { textAlign: 'left', width: '90%' },
    headerImage: {
        width: SPACING.USER_IMAGE,
        height: SPACING.USER_IMAGE,
        backgroundColor: 'grey',
        marginBottom: SPACING.MARGIN * 2,
        height: SPACING.MARGIN * 6,
        width: SPACING.MARGIN * 6,
    },
    popableContainer: {
        //width: '25%',
    },
    inputContainer: {
        width: '90%',
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
        height: SPACING.MARGIN * 2,
        borderRadius: SPACING.MARGIN,
        marginBottom: SPACING.MARGIN,
        marginTop: SPACING.MARGIN / 2,
        borderColor: 'red',
        borderStyle: 'solid',
    },
    textInputContainer: {
        margin: 0,
        padding: 0,
        borderBottomWidth: 0,
        paddingHorizontal: SPACING.MARGIN / 2,
        paddingLeft: 0,
    },
    textIconContainer: {
        marginHorizontal: SPACING.MARGIN / 4,
    },
    uploadProfilePicture: {
        position: 'absolute',
        bottom: -SPACING.MARGIN / 1.75,
        right: -SPACING.MARGIN / 1.75,
    },
    buttonBar: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    buttonContainer: {
        borderRadius: SPACING.MARGIN,
        paddingVertical: SPACING.MARGIN / 2,
        paddingHorizontal: SPACING.MARGIN,
        backgroundColor: Colors.primary,
    },
    buttonStyle: {
        padding: 0,
        backgroundColor: 'rgba(0,0,0,0)',
    },
});

export default CreateGroupChat;
