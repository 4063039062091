import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { db } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { Text } from 'react-native';
import firebase from 'firebase';

export default function SelectApartment({ navigation, route }) {
    const [apartments, setApartments] = useState([]);

    const params = route.params;

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'WG Auswählen',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    useEffect(() => {
        const unsub = db
            .collection('apartment')
            .where('isPublic', '==', 'true')
            .onSnapshot((queryRes) =>
                setApartments(
                    queryRes.docs.map((docs) => ({
                        id: docs.id,
                        data: docs.data(),
                    }))
                )
            );

        return unsub;
    }, []);

    //console.log(params);

    function selectApartment(id) {
        db.collection('msg_rooms')
            .doc(params.groupID)
            .update({
                selectedApartment: id,
                tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() =>
                navigation.navigate({
                    name: 'groupDetails',
                    params: { selectedApartment: id },
                    merge: true,
                })
            );
    }

    function removeSelection() {
        db.collection('msg_rooms')
            .doc(params.groupID)
            .update({
                selectedApartment: firebase.firestore.FieldValue.delete(),
                tempUpdate: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => navigation.goBack());
    }

    return (
        <SafeAreaView edges={['left', 'right']}>
            {apartments.map(({ id, data }) => (
                <ListItem
                    key={id}
                    topDivider
                    onPress={() => {
                        params.selectedApartment !== id
                            ? selectApartment(id)
                            : removeSelection();
                    }}
                >
                    <Avatar source={{ uri: data.photoURL }} rounded />
                    <ListItem.Content style={{ flex: 1 }}>
                        <ListItem.Title>{data.name}</ListItem.Title>
                        <ListItem.Subtitle style={{ fontWeight: '200' }}>
                            {data.adress}
                        </ListItem.Subtitle>
                    </ListItem.Content>
                    {params.selectedApartment === id ? (
                        <Icon
                            name="check"
                            type="simple-line-icon"
                            color="green"
                        />
                    ) : (
                        <></>
                    )}
                </ListItem>
            ))}
            <Text
                style={{
                    width: '100%',
                    fontWeight: '100',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: SPACING.MARGIN,
                    textAlign: 'center',
                }}
            >
                Das waren unsere letzten Einträge!
            </Text>
        </SafeAreaView>
    );
}
