import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
    Image,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth, db } from '../firebase';
import { Avatar, Button, Icon, Badge } from 'react-native-elements';
import * as ImagePicker from 'expo-image-picker';
import * as Notifications from 'expo-notifications';
import { Linking } from 'react-native';
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import VersionOverlay from './components/versionOverlay';

function Home({ navigation }) {
    const user = auth.currentUser;

    const [userDetail, setUserDetail] = useState([]);

    const [allUserGeoPoint, setAllUserGeoPoint] = useState([]);
    const [allApartmentGeoPoint, setAllApartmentGeoPoint] = useState([]);
    const [unseenMessages, setUnseenMessages] = useState(false);

    var initials = 'FW';

    if (user.displayName) {
        if (user.displayName.trim().indexOf(' ') != -1) {
            const fullName = user.displayName.split(' ');
            initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
            initials = initials.toUpperCase();
        } else {
            initials = user.displayName.charAt(0);
        }
    }

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Dashboard',
            headerShown: false,
        });
    }, []);

    useEffect(() => {
        const unsubUserDetail = db
            .collection('user')
            .doc(user.uid)
            .onSnapshot((queryRes) => setUserDetail(queryRes.data()));

        const unsubUnseenMessage = db
            .collection('msg_rooms')
            .where('users', 'array-contains', user.uid)
            .onSnapshot((snapshot) => {
                setUnseenMessages(false);
                snapshot.docs.map((doc) => {
                    db.collection('msg_rooms')
                        .doc(doc.id)
                        .collection('messages')
                        .onSnapshot((snapshot) => {
                            snapshot.docs.map((doc) => {
                                if (!doc.data().seenBy.includes(user.uid)) {
                                    setUnseenMessages(true);
                                }
                            });
                        });
                });
            });

        db.collection('user')
            .where('visible', '==', 'all')
            .orderBy('geolocation', 'asc')
            .get()
            .then((res) =>
                setAllUserGeoPoint(
                    res.docs.map((doc) => ({
                        'geolocation': doc.data().geolocation,
                        'displayName': doc.data().displayName,
                    }))
                )
            );

        db.collection('apartment')
            .where('isPublic', '==', true)
            .orderBy('geolocation', 'asc')
            .get()
            .then((res) =>
                setAllApartmentGeoPoint(
                    res.docs.map((doc) => ({
                        'geolocation': doc.data().geolocation,
                        'title': doc.data().name,
                        'adress': doc.data().adress,
                    }))
                )
            );

        if (Platform.OS !== 'web') {
            (async () => {
                const { status } =
                    await ImagePicker.requestMediaLibraryPermissionsAsync();

                if (status !== 'granted') {
                    await ImagePicker.requestMediaLibraryPermissionsAsync();
                }
            })();
        }

        Location.requestForegroundPermissionsAsync();

        return unsubUserDetail, unsubUnseenMessage;
    }, []);

    async function registerForPushNotifications() {}

    function doNothing(params) {}

    async function openURL(url) {
        const supported = await Linking.canOpenURL(url);

        if (supported) {
            await Linking.openURL(url);
        }
    }

    return (
        <SafeAreaView
            edges={['top', 'right', 'left']}
            style={{ flex: 1 /*backgroundColor: Colors.background*/ }}
        >
            <ScrollView
                contentContainerStyle={{ alignItems: 'center' }}
                style={styles.container}
            >
                <VersionOverlay />
                <View style={styles.header}>
                    <Avatar
                        rounded
                        title={initials}
                        source={{ uri: userDetail.photoURL }}
                        containerStyle={styles.headerImage}
                        titleStyle={{ fontSize: 26 }}
                    >
                        <Icon
                            containerStyle={styles.editProfileIcon}
                            size={SPACING.MARGIN / 2}
                            name="pencil"
                            type="simple-line-icon"
                            rounded
                            reverse
                            color={Colors.accent}
                            reverseColor={Colors.background}
                            onPress={() => navigation.navigate('editProfile')}
                        />
                    </Avatar>
                    <Text style={styles.headerText}>
                        {userDetail.displayName
                            ? userDetail.displayName
                            : userDetail.email}
                    </Text>
                    <Icon
                        name="bubbles"
                        type="simple-line-icon"
                        size={32}
                        color={Colors.primary}
                        reverse
                        onPress={() => navigation.push('chatlist')}
                    />
                    {unseenMessages ? (
                        <Badge
                            containerStyle={{
                                position: 'absolute',
                                right: SPACING.MARGIN / 2.5,
                                top: SPACING.MARGIN / 2.5,
                            }}
                            badgeStyle={{
                                backgroundColor: Colors.accent,
                                height: SPACING.MARGIN / 1.5,
                                width: SPACING.MARGIN / 1.5,
                                borderRadius: SPACING.MARGIN / 2,
                            }}
                            textStyle={{ fontSize: 14 }}
                        />
                    ) : (
                        <></>
                    )}
                </View>
                <View style={styles.roadmapContainer}>
                    <View style={styles.roadmap}>
                        <View
                            style={[
                                styles.roadmapPoint,
                                user.emailVerified ? styles.roadmapSuccess : {},
                            ]}
                        >
                            {user.emailVerified ? (
                                <Icon name="check" color="green" />
                            ) : (
                                <Text
                                    style={
                                        user.emailVerified
                                            ? styles.roadmapSuccess
                                            : {}
                                    }
                                >
                                    1
                                </Text>
                            )}
                        </View>
                        <View
                            style={[
                                styles.roadmapText,
                                user.emailVerified ? styles.roadmapSuccess : {},
                            ]}
                        >
                            <Text
                                style={
                                    user.emailVerified
                                        ? styles.roadmapSuccess
                                        : {}
                                }
                            >
                                Email bestätigen
                            </Text>
                        </View>
                    </View>
                    <View style={styles.roadmap}>
                        <View
                            style={[
                                styles.roadmapPoint,
                                userDetail.photoURL
                                    ? styles.roadmapSuccess
                                    : {},
                            ]}
                        >
                            {userDetail.photoURL ? (
                                <Icon name="check" color="green" />
                            ) : (
                                <Text
                                    style={
                                        userDetail.photoUrl
                                            ? styles.roadmapSuccess
                                            : {}
                                    }
                                >
                                    2
                                </Text>
                            )}
                        </View>
                        <View
                            style={[
                                styles.roadmapText,
                                userDetail.photoURL
                                    ? styles.roadmapSuccess
                                    : {},
                            ]}
                        >
                            <Text
                                style={
                                    userDetail.photoURL
                                        ? styles.roadmapSuccess
                                        : {}
                                }
                            >
                                Profilbild hinzufügen
                            </Text>
                        </View>
                    </View>
                    <View style={styles.roadmap}>
                        <View
                            style={[
                                styles.roadmapPoint,
                                userDetail.profileEdited
                                    ? styles.roadmapSuccess
                                    : {},
                            ]}
                        >
                            {userDetail.profileEdited ? (
                                <Icon name="check" color="green" />
                            ) : (
                                <Text
                                    style={
                                        userDetail.profileEdited
                                            ? styles.roadmapSuccess
                                            : {}
                                    }
                                >
                                    3
                                </Text>
                            )}
                        </View>
                        <View style={styles.roadmapText}>
                            <Text
                                style={
                                    userDetail.profileEdited
                                        ? styles.roadmapSuccess
                                        : {}
                                }
                            >
                                Daten vervollständigen
                            </Text>
                        </View>
                    </View>
                    <View style={styles.roadmap}>
                        <View
                            style={[
                                styles.roadmapPoint,
                                userDetail.visibilityEdited
                                    ? styles.roadmapSuccess
                                    : {},
                            ]}
                        >
                            {userDetail.visibilityEdited ? (
                                <Icon name="check" color="green" />
                            ) : (
                                <Text
                                    style={
                                        userDetail.isEdited
                                            ? styles.roadmapSuccess
                                            : {}
                                    }
                                >
                                    4
                                </Text>
                            )}
                        </View>
                        <View style={styles.roadmapText}>
                            <Text
                                style={
                                    userDetail.visibilityEdited
                                        ? styles.roadmapSuccess
                                        : {}
                                }
                            >
                                Sichtbarkeitseinstellungen anpassen
                            </Text>
                        </View>
                    </View>
                </View>
                <Button
                    onPress={() => navigation.navigate('userlist')}
                    icon={<Icon name="people" size={32} color="white" />}
                    type="clear"
                    title="Alle User"
                    titleStyle={[
                        styles.dashTitle,
                        {
                            marginLeft: SPACING.MARGIN / 2,
                        },
                    ]}
                    containerStyle={[
                        { backgroundColor: Colors.primary },
                        styles.dashContainer,
                    ]}
                    buttonStyle={{
                        width: '100%',
                        paddingVertical: SPACING.MARGIN / 1.5,
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                />
                <Button
                    onPress={() => navigation.navigate('apartmentList')}
                    icon={<Icon name="house" size={32} color="white" />}
                    title="Alle WG's"
                    type="clear"
                    titleStyle={[
                        styles.dashTitle,
                        { marginLeft: SPACING.MARGIN / 2 },
                    ]}
                    containerStyle={[
                        { backgroundColor: Colors.accent },
                        styles.dashContainer,
                    ]}
                    buttonStyle={{
                        width: '100%',
                        paddingVertical: SPACING.MARGIN / 1.5,
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                />
                <Button
                    onPress={() => openURL('https://fitwohnen.de/blog/')}
                    icon={
                        <Icon
                            name="feed"
                            type="simple-line-icon"
                            size={24}
                            color="white"
                        />
                    }
                    type="clear"
                    title="Unser Newsfeed"
                    titleStyle={[
                        styles.dashTitle,
                        {
                            marginLeft: SPACING.MARGIN / 2,
                        },
                    ]}
                    containerStyle={[
                        { backgroundColor: Colors.accentDark },
                        styles.dashContainer,
                    ]}
                    buttonStyle={{
                        width: '100%',
                        paddingVertical: SPACING.MARGIN / 1.5,
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                />

                <View
                    style={[
                        {
                            backgroundColor: 'white',
                            height: SPACING.MARGIN * 20,
                            overflow: 'hidden',
                        },
                        styles.dashContainer,
                    ]}
                >
                    <MapView
                        style={{ flex: 1 }}
                        region={
                            userDetail.geolocation
                                ? {
                                      'latitude':
                                          userDetail.geolocation.latitude,
                                      'longitude':
                                          userDetail.geolocation.longitude,
                                      'latitudeDelta': 1.5,
                                      'longitudeDelta': 1.5,
                                  }
                                : {
                                      'latitude': 0,
                                      'longitude': 0,
                                      'latitudeDelta': 0,
                                      'longitudeDelta': 0,
                                  }
                        }
                        provider={PROVIDER_GOOGLE}
                    >
                        {Platform.OS !== 'web' ? (
                            <>
                                {userDetail.geolocation ? (
                                    <Marker
                                        coordinate={{
                                            'latitude':
                                                userDetail.geolocation.latitude,
                                            'longitude':
                                                userDetail.geolocation
                                                    .longitude,
                                        }}
                                        title="Ich"
                                        icon={require('../assets/icons/simple-line-icons_ghost.png')}
                                    />
                                ) : (
                                    <></>
                                )}

                                {allUserGeoPoint.map((userPoint, index) => (
                                    <Marker
                                        key={index}
                                        coordinate={{
                                            'latitude':
                                                userPoint.geolocation.latitude,
                                            'longitude':
                                                userPoint.geolocation.longitude,
                                        }}
                                        title={userPoint.displayName}
                                        icon={require('../assets/icons/simple-line-icons_user.png')}
                                    />
                                ))}
                                {allApartmentGeoPoint.map(
                                    (apartmentPoint, index) => (
                                        <Marker
                                            key={index}
                                            coordinate={{
                                                'latitude':
                                                    apartmentPoint.geolocation
                                                        .latitude,
                                                'longitude':
                                                    apartmentPoint.geolocation
                                                        .longitude,
                                            }}
                                            title={apartmentPoint.title}
                                            description={apartmentPoint.adress}
                                            pinColor={Colors.primary}
                                            icon={require('../assets/icons/simple-line-icons_home.png')}
                                        />
                                    )
                                )}
                            </>
                        ) : (
                            <>
                                {userDetail.geolocation ? (
                                    <MapView.Marker
                                        coordinate={{
                                            'latitude':
                                                userDetail.geolocation.latitude,
                                            'longitude':
                                                userDetail.geolocation
                                                    .longitude,
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                                {allUserGeoPoint.map((userPoint, index) => (
                                    <MapView.Marker
                                        key={index}
                                        coordinate={{
                                            'latitude':
                                                userPoint.geolocation.latitude,
                                            'longitude':
                                                userPoint.geolocation.longitude,
                                        }}
                                    />
                                ))}
                                {allApartmentGeoPoint.map(
                                    (apartmentPoint, index) => (
                                        <MapView.Marker
                                            key={index}
                                            coordinate={{
                                                'latitude':
                                                    apartmentPoint.geolocation
                                                        .latitude,
                                                'longitude':
                                                    apartmentPoint.geolocation
                                                        .longitude,
                                            }}
                                            pinColor={Colors.primary}
                                        />
                                    )
                                )}
                            </>
                        )}
                    </MapView>
                </View>
                <Button
                    onPress={() => navigation.push('settings')}
                    icon={<Icon name="settings" size={32} color="white" />}
                    type="clear"
                    title="Einstellungen"
                    titleStyle={[
                        styles.dashTitle,
                        { marginLeft: SPACING.MARGIN / 2 },
                    ]}
                    containerStyle={[
                        {
                            backgroundColor: 'grey',
                            marginBottom: SPACING.MARGIN,
                        },
                        styles.dashContainer,
                    ]}
                    buttonStyle={{
                        width: '100%',
                        paddingVertical: SPACING.MARGIN / 1.5,
                        paddingHorizontal: SPACING.MARGIN,
                    }}
                />
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    header: {
        marginTop: SPACING.MARGIN,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '90%',
        marginBottom: SPACING.MARGIN * 4,
    },
    headerImage: {
        width: SPACING.USER_IMAGE,
        height: SPACING.USER_IMAGE,
        backgroundColor: 'grey',
    },
    headerText: {
        fontWeight: '600',
        fontSize: 24,
        color: Colors.primaryDark,
        maxWidth: '50%',
    },
    editProfileIcon: {
        position: 'absolute',
        bottom: -SPACING.MARGIN / 1.75,
        right: -SPACING.MARGIN / 1.75,
    },
    dashContainer: {
        marginTop: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        width: '90%',
    },
    dashTitle: {
        color: 'white',
        fontSize: 26,
        fontWeight: '600',
    },
    settingsContainer: {
        marginTop: SPACING.MARGIN,
        padding: SPACING.MARGIN / 2,
        paddingHorizontal: SPACING.MARGIN,
        width: '90%',
        borderRadius: SPACING.MARGIN,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    roadmapContainer: {
        width: '90%',
        paddingHorizontal: SPACING.MARGIN * 2,
    },
    roadmap: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: SPACING.MARGIN / 4,
    },
    roadmapPoint: {
        height: SPACING.MARGIN,
        width: SPACING.MARGIN,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 25,
    },
    roadmapText: {
        height: SPACING.MARGIN,
        justifyContent: 'center',
        marginLeft: SPACING.MARGIN / 2,
    },
    roadmapSuccess: {
        color: 'green',
        borderColor: 'green',
    },
});

export default Home;
