import React, { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator } from 'react-native';
import { Text } from 'react-native';
import {
    Avatar,
    Badge,
    Button,
    FAB,
    Icon,
    Image,
    Input,
    ListItem,
} from 'react-native-elements';
import { SafeAreaView } from 'react-native-safe-area-context';
import { auth, db, storage } from '../firebase';
import { Colors, SPACING } from '../assets/colors/colors';
import { View } from 'react-native';
import dateFormat from 'dateformat';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native';
import firebase from 'firebase';
import * as ImagePicker from 'expo-image-picker';
import * as Location from 'expo-location';

function EditApartment({ route, navigation }) {
    const params = route.params;

    const editType = params ? 'edit' : 'create';

    const [isPublic, setIsPublic] = useState(false);

    const [apartmentDetail, setApartmentDetail] = useState([]);

    const [apartmentURL, setApartmentURL] = useState('');
    const [apartmentPhotoURL, setApartmentPhotoURL] = useState('');
    const [apartmentName, setApartmentName] = useState('');
    const [apartmentAdress, setApartmentAdress] = useState('');
    const [apartmentDescription, setApartmentDescription] = useState('');
    const [apartmentType, setApartmentType] = useState('');
    const [apartmentSize, setApartmentSize] = useState('');
    const [apartmentRooms, setApartmentRooms] = useState('');
    const [apartmentRoomsReserved, setApartmentRoomsReserved] = useState('');
    const [apartmentFloor, setApartmentFloor] = useState('');
    const [apartmentRent, setApartmentRent] = useState('');
    const [apartmentExtraCost, setApartmentExtraCost] = useState('');
    const [apartmentRenovated, setApartmentRenovated] = useState('');
    const [apartmentObtainable, setApartmentObtainable] = useState(
        timestampToDate()
    );
    const [apartmentSpecialProperties, setApartmentSpecialProperties] =
        useState([]);

    const [reRender, setReRender] = useState(false);

    const user = auth.currentUser;

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
            title: 'WG bearbeiten',
        });
    }, []);

    function timestampToDate(timestamp) {
        var date = new Date();

        if (timestamp) {
            date = timestamp.toDate();
        }

        return dateFormat(date, 'dd.mm.yy');
    }

    useEffect(() => {
        if (editType === 'edit') {
            db.collection('apartment')
                .doc(params.apartmentID)
                .get()
                .then((snapshot) => {
                    var apartmentData = snapshot.data();
                    setApartmentPhotoURL(apartmentData.photoURL);
                    setIsPublic(apartmentData.isPublic);
                    setApartmentName(apartmentData.name);
                    setApartmentAdress(apartmentData.adress);
                    setApartmentDescription(apartmentData.description);
                    setApartmentType(apartmentData.type);
                    setApartmentSize(apartmentData.apartment_size.toString());
                    setApartmentRooms(apartmentData.rooms_free.toString());
                    setApartmentRoomsReserved(
                        apartmentData.rooms_reserved.toString()
                    );
                    setApartmentFloor(apartmentData.floor);
                    setApartmentRent(apartmentData.rent.toString());
                    setApartmentExtraCost(apartmentData.extra_cost.toString());
                    setApartmentRenovated(apartmentData.renovated.toString());
                    setApartmentObtainable(apartmentData.obtainable);
                    setApartmentURL(apartmentData.apartmentURL);
                });

            db.collection('apartment')
                .doc(params.apartmentID)
                .collection('properties')
                .get()
                .then((snapshot) =>
                    setApartmentSpecialProperties(
                        snapshot.docs.map((doc) => ({
                            id: doc.id,
                            data: doc.data(),
                        }))
                    )
                );
        }
    }, []);

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 1,
        });

        if (!result.cancelled) {
            if (editType === 'edit') {
                const storageRef = storage.ref();
                var imagesRef = storageRef.child(
                    'apartment/' + params.apartmentID + '.jpg'
                );
                var response = await fetch(result.uri);
                const blob = await response.blob();
                await imagesRef.put(blob);

                const photoURL = await imagesRef.getDownloadURL();

                setApartmentPhotoURL(photoURL);
            } else if (editType === 'create') {
                setApartmentPhotoURL(result.uri);
            }
        }
    };

    function addSpecialProperties() {
        var tempArray = apartmentSpecialProperties;

        tempArray.push({
            id: Math.floor(Math.random() * 1000),
            data: { title: '', value: '' },
        });

        setApartmentSpecialProperties(tempArray);
        setReRender(!reRender);
        //console.log(apartmentSpecialProperties);
    }

    function updateSpecialProperties(index, data, type) {
        var tempArray = apartmentSpecialProperties;

        if (type === 'title') {
            tempArray[index].data.title = data;
        } else if (type === 'value') {
            tempArray[index].data.value = data;
        }

        setApartmentSpecialProperties(tempArray);
        setReRender(!reRender);
    }

    function getApartmentType(type) {
        if (type === 'location') {
            return 'Standort';
        } else if (type === 'apartment') {
            return 'WG';
        } else {
            return '';
        }
    }

    async function updateGeoCode(place, apartmentID) {
        try {
            Location.setGoogleApiKey('AIzaSyCOfwdT1MFOnRCS2_AhMLONjbToRwQrJ9I');
            let result = await Location.geocodeAsync(place);
            db.collection('apartment')
                .doc(apartmentID)
                .update({
                    geolocation: new firebase.firestore.GeoPoint(
                        result[0].latitude,
                        result[0].longitude
                    ),
                });
        } catch (e) {
            console.log(e);
        } finally {
        }
    }

    function saveApartment() {
        if (editType === 'edit') {
            db.collection('apartment').doc(params.apartmentID).update({
                adress: apartmentAdress,
                apartmentURL: apartmentURL,
                photoURL: apartmentPhotoURL,
                apartment_size: apartmentSize,
                description: apartmentDescription,
                extra_cost: apartmentExtraCost,
                floor: apartmentFloor,
                isPublic: isPublic,
                name: apartmentName,
                obtainable: apartmentObtainable,
                photoURL: apartmentPhotoURL,
                renovated: apartmentRenovated,
                rent: apartmentRent,
                rooms_free: apartmentRooms,
                rooms_reserved: apartmentRoomsReserved,
                last_editet_at: firebase.firestore.FieldValue.serverTimestamp(),
            });

            updateGeoCode(apartmentAdress, params.apartmentID);

            apartmentSpecialProperties
                .filter(({ data }) => data.title !== '' && data.value !== '')
                .map(({ data, id }) => {
                    var apartmentRef = db
                        .collection('apartment')
                        .doc(params.apartmentID)
                        .collection('properties');

                    apartmentRef
                        .where('title', '==', data.title)
                        .where('value', '==', data.value)
                        .get()
                        .then((snapshot) => {
                            if (!snapshot.docs[0]) {
                                apartmentRef.add({
                                    title: data.title,
                                    value: data.value,
                                });
                            }
                        });
                });

            navigation.goBack();
        } else if (editType === 'create') {
            const res = db
                .collection('apartment')
                .add({
                    adress: apartmentAdress,
                    apartmentURL: apartmentURL,
                    apartment_size: apartmentSize,
                    description: apartmentDescription,
                    extra_cost: apartmentExtraCost,
                    floor: apartmentFloor,
                    isPublic: isPublic,
                    name: apartmentName,
                    obtainable: apartmentObtainable,
                    photoURL: apartmentPhotoURL,
                    renovated: apartmentRenovated,
                    rent: apartmentRent,
                    rooms_free: apartmentRooms,
                    rooms_reserved: apartmentRoomsReserved,
                    last_editet_at:
                        firebase.firestore.FieldValue.serverTimestamp(),
                    created_at: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(async (res) => {
                    updateGeoCode(apartmentAdress, res.id);

                    apartmentSpecialProperties
                        .filter(
                            ({ data }) => data.title !== '' && data.value !== ''
                        )
                        .map(({ data, id }) => {
                            var apartmentRef = db
                                .collection('apartment')
                                .doc(res.id)
                                .collection('properties');

                            apartmentRef
                                .where('title', '==', data.title)
                                .where('value', '==', data.value)
                                .get()
                                .then((snapshot) => {
                                    if (!snapshot.docs[0]) {
                                        apartmentRef.add({
                                            title: data.title,
                                            value: data.value,
                                        });
                                    }
                                });
                        });

                    if (apartmentPhotoURL !== '') {
                        const storageRef = storage.ref();
                        var imagesRef = storageRef.child(
                            'apartment/' + res.id + '.jpg'
                        );
                        var response = await fetch(apartmentPhotoURL);
                        const blob = await response.blob();
                        await imagesRef.put(blob);

                        const photoURL = await imagesRef.getDownloadURL();

                        db.collection('apartment')
                            .doc(res.id)
                            .update({ photoURL: photoURL });
                    }
                });

            navigation.goBack();
        }
    }

    return (
        <SafeAreaView style={styles.container} edges={['left', 'right']}>
            <ScrollView
                style={{
                    flex: 1,
                }}
                contentContainerStyle={{
                    flexGrow: 1,
                }}
            >
                <Avatar
                    containerStyle={{
                        width: '100%',
                        height: 350,
                        backgroundColor: 'lightgrey',
                    }}
                    icon={{
                        name: 'camera',
                        type: 'simple-line-icon',
                        color: 'white',
                        size: 48,
                    }}
                    source={{ uri: apartmentPhotoURL }}
                    height={350}
                    onPress={pickImage}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingHorizontal: SPACING.MARGIN,
                        marginTop: -SPACING.MARGIN,
                    }}
                >
                    <FAB
                        containerStyle={[styles.backFAB, {}]}
                        icon={{
                            name: 'arrow-left',
                            type: 'simple-line-icon',
                            color: 'white',
                        }}
                        color={Colors.accent}
                        onPress={() => navigation.goBack()}
                    />

                    <FAB
                        containerStyle={[styles.backFAB, {}]}
                        icon={{
                            name: isPublic ? 'globe' : 'pencil',
                            type: 'simple-line-icon',
                            color: 'white',
                        }}
                        color={Colors.primary}
                        title={isPublic ? 'Öffentlich' : 'Entwurf'}
                        iconRight
                        onPress={() => setIsPublic(!isPublic)}
                    />
                </View>
                <View style={styles.detailContainer}>
                    <Input
                        inputStyle={styles.detailTitle}
                        containerStyle={styles.inputContainer}
                        inputContainerStyle={[styles.inputContainer, {}]}
                        errorStyle={styles.inputError}
                        style={{
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                        placeholder="WG Name"
                        value={apartmentName}
                        onChangeText={setApartmentName}
                    />

                    <Input
                        inputStyle={styles.detailSubtitle}
                        containerStyle={styles.inputContainer}
                        inputContainerStyle={styles.inputContainer}
                        placeholder="WG Adresse"
                        value={apartmentAdress}
                        errorStyle={styles.inputError}
                        style={{
                            paddingTop: 0,
                        }}
                        onChangeText={setApartmentAdress}
                    />

                    <Text style={styles.detailSmallTitle}>Beschreibung:</Text>
                    <Input
                        inputStyle={{ fontSize: 16 }}
                        containerStyle={styles.inputContainer}
                        inputContainerStyle={styles.inputContainer}
                        placeholder="Über die WG"
                        value={apartmentDescription}
                        errorStyle={styles.inputError}
                        style={{
                            paddingTop: 0,
                        }}
                        onChangeText={setApartmentDescription}
                        multiline
                    />
                    <Text style={styles.detailSmallTitle}>Eigenschaften:</Text>
                    <View style={styles.detailListContainer}>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Typ:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="WG / Standort"
                                    value={getApartmentType(apartmentType)}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentType}
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Online URL:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="https://fitwohnen.de/"
                                    value={apartmentURL}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentURL}
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Wohnfläche:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="99"
                                    value={apartmentSize}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={(event) =>
                                        setApartmentSize(event)
                                    }
                                    rightIcon={
                                        <Text style={styles.listItemSubtitle}>
                                            m^2
                                        </Text>
                                    }
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Wohnräume (verfügbar):
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="3"
                                    value={apartmentRooms}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentRooms}
                                />
                            </ListItem>
                        </View>
                        <View style={[styles.detailListItemContainer, {}]}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Wohnräume (reserviert):
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="0"
                                    value={apartmentRoomsReserved}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentRoomsReserved}
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Stockwerk:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="EG"
                                    value={apartmentFloor}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="givenName"
                                    onChangeText={setApartmentFloor}
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Kaltmiete:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="300"
                                    value={apartmentRent}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentRent}
                                    rightIcon={
                                        <Text style={styles.listItemSubtitl}>
                                            €
                                        </Text>
                                    }
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Nebenkosten:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="70"
                                    value={apartmentExtraCost}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentExtraCost}
                                    rightIcon={
                                        <Text style={styles.listItemSubtitle}>
                                            €
                                        </Text>
                                    }
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Zuletzt Renoviert:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder="2021"
                                    value={apartmentRenovated}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="telephoneNumber"
                                    onChangeText={setApartmentRenovated}
                                />
                            </ListItem>
                        </View>
                        <View style={styles.detailListItemContainer}>
                            <ListItem containerStyle={styles.detailListItem}>
                                <ListItem.Title style={styles.listItemTitle}>
                                    Beziehbar ab:
                                </ListItem.Title>
                                <Input
                                    inputStyle={styles.listItemSubtitle}
                                    containerStyle={[styles.inputContainer, {}]}
                                    inputContainerStyle={styles.inputContainer}
                                    placeholder={timestampToDate()}
                                    value={apartmentObtainable}
                                    style={{
                                        paddingTop: 0,
                                    }}
                                    errorStyle={styles.inputError}
                                    textContentType="givenName"
                                    onChangeText={setApartmentObtainable}
                                />
                            </ListItem>
                        </View>
                    </View>
                    <View>
                        <Text style={styles.detailSmallTitle}>
                            Besondere Eigenschaften:
                        </Text>
                        {apartmentSpecialProperties.map(
                            ({ id, data }, index) => (
                                <View
                                    key={id}
                                    style={styles.detailListItemContainer}
                                >
                                    <ListItem
                                        containerStyle={styles.detailListItem}
                                    >
                                        <Input
                                            inputStyle={styles.listItemTitle}
                                            containerStyle={[
                                                styles.inputContainer,
                                                { width: '45%' },
                                            ]}
                                            inputContainerStyle={
                                                styles.inputContainer
                                            }
                                            placeholder="Wert"
                                            value={data.title}
                                            style={{
                                                paddingTop: 0,
                                            }}
                                            errorStyle={styles.inputError}
                                            textContentType="givenName"
                                            onChangeText={(event) =>
                                                updateSpecialProperties(
                                                    index,
                                                    event,
                                                    'title'
                                                )
                                            }
                                            rightIcon={<Text>:</Text>}
                                        />
                                        <Input
                                            inputStyle={styles.listItemSubtitle}
                                            containerStyle={[
                                                styles.inputContainer,
                                                { width: '45%' },
                                            ]}
                                            inputContainerStyle={
                                                styles.inputContainer
                                            }
                                            placeholder="Wert"
                                            value={data.value}
                                            style={{
                                                paddingTop: 0,
                                            }}
                                            errorStyle={styles.inputError}
                                            textContentType="givenName"
                                            onChangeText={(event) =>
                                                updateSpecialProperties(
                                                    index,
                                                    event,
                                                    'value'
                                                )
                                            }
                                        />
                                    </ListItem>
                                </View>
                            )
                        )}
                        <View style={styles.detailListItemContainer}>
                            <ListItem
                                containerStyle={[
                                    styles.detailListItem,
                                    { justifyContent: 'center' },
                                ]}
                            >
                                <Icon
                                    name="plus"
                                    type="simple-line-icon"
                                    reverse
                                    onPress={() => addSpecialProperties()}
                                />
                            </ListItem>
                        </View>
                        <Button
                            containerStyle={{
                                borderRadius: SPACING.MARGIN / 2,
                                width: '50%',
                                alignSelf: 'center',
                                marginTop: SPACING.MARGIN,
                            }}
                            buttonStyle={{
                                backgroundColor: Colors.primary,
                            }}
                            title="Speichern"
                            onPress={saveApartment}
                        />
                    </View>
                    <Text
                        style={{
                            paddingVertical: SPACING.MARGIN,
                            fontWeight: '100',
                        }}
                    >
                        Inseriert seit:
                        {' ' + timestampToDate(apartmentDetail.created_at)}
                    </Text>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: { flex: 1 },
    backFAB: {
        position: 'relative',
    },
    detailContainer: {
        alignSelf: 'center',
        width: '70%',
        zIndex: 1,
        paddingTop: SPACING.MARGIN,
    },
    detailTitle: {
        paddingTop: SPACING.MARGIN,
        fontWeight: '600',
        fontSize: 24,
    },
    detailSubtitle: {
        fontWeight: '300',
        fontSize: 14,
        paddingBottom: SPACING.MARGIN,
    },
    detailSmallTitle: {
        fontSize: 16,
        fontWeight: '500',
        paddingBottom: SPACING.MARGIN / 2,
        paddingTop: SPACING.MARGIN,
    },
    detailListContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    detailListItemContainer: {
        minWidth: 250,
        width: Platform.OS === 'web' ? '49%' : '100%',
    },
    detailListItem: {
        backgroundColor: 'rgba(0,0,0,0)',
        padding: 0,
        paddingVertical: SPACING.MARGIN / 4,
        margin: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    listItemTitle: {
        fontWeight: '400',
    },
    listItemSubtitle: {
        fontWeight: '300',
        textAlign: 'right',
        marginRight: 5,
    },
    inputContainer: {
        borderBottomWidth: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    inputError: {
        height: 0,
        padding: 0,
        margin: 0,
    },
});

export default EditApartment;
