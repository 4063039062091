import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useEffect } from 'react';
import { Settings, StatusBar, StyleSheet } from 'react-native';
import Home from './screens/home';
import FirstUp from './screens/login/firstUp';
import { Colors } from './assets/colors/colors';
import Login from './screens/login/login';
import Register from './screens/login/register';
import { auth, messaging } from './firebase';
import LoadinScreenStart from './screens/loadinScreenStart';
import ChatList from './screens/chatList';
import SettingsScreen from './screens/settings';
import UserList from './screens/userList';
import SingleChat from './screens/singleChat';
import EditProfile from './screens/editProfile';
import ApartmentList from './screens/apartment_list';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import ApartmentDetail from './screens/apartmentDetail';
import UserDetail from './screens/userDetail';
import GroupChat from './screens/groupChat';
import GroupDetail from './screens/groupDetail';
import SelectApartment from './screens/selectApartment';
import AddUserToGroup from './screens/addUserToGroup';
import SelectUserForSingleChat from './screens/selectUserForSingleChat';
import CreateGroupChat from './screens/createGroupChat';
import ResetPassword from './screens/login/resetPassword';
import EditApartment from './screens/editApartment';
import * as Notification from 'expo-notifications';

const Stack = createStackNavigator();

export default function App() {
    useEffect(() => {}, []);

    return (
        <SafeAreaProvider>
            <NavigationContainer style={styles.container}>
                <StatusBar
                    barStyle="light-content"
                    backgroundColor={Colors.primary}
                />
                <Stack.Navigator
                    style={styles.container}
                    initialRouteName={'loading'}
                    screenOptions={{}}
                >
                    <Stack.Screen
                        name="loading"
                        component={LoadinScreenStart}
                    />

                    <Stack.Screen name="firstup" component={FirstUp} />
                    <Stack.Screen name="login" component={Login} />
                    <Stack.Screen name="register" component={Register} />
                    <Stack.Screen
                        name="resetPassword"
                        component={ResetPassword}
                    />

                    <Stack.Screen name="home" component={Home} />

                    <Stack.Screen name="chatlist" component={ChatList} />
                    <Stack.Screen name="singleChat" component={SingleChat} />

                    <Stack.Screen name="groupChat" component={GroupChat} />
                    <Stack.Screen name="groupDetails" component={GroupDetail} />

                    <Stack.Screen name="userlist" component={UserList} />
                    <Stack.Screen name="userDetails" component={UserDetail} />

                    <Stack.Screen name="settings" component={SettingsScreen} />
                    <Stack.Screen name="editProfile" component={EditProfile} />
                    <Stack.Screen
                        name="selectUserForSingleChat"
                        component={SelectUserForSingleChat}
                    />

                    <Stack.Screen
                        name="createGroupChat"
                        component={CreateGroupChat}
                    />

                    <Stack.Screen
                        name="addUserToGroup"
                        component={AddUserToGroup}
                    />
                    <Stack.Screen
                        name="selectApartment"
                        component={SelectApartment}
                    />
                    <Stack.Screen
                        name="apartmentList"
                        component={ApartmentList}
                    />
                    <Stack.Screen
                        name="apartmentDetail"
                        component={ApartmentDetail}
                    />
                    <Stack.Screen
                        name="editApartment"
                        component={EditApartment}
                    />
                </Stack.Navigator>
            </NavigationContainer>
        </SafeAreaProvider>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.background,
    },
});
