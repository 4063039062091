import React, { useEffect, useLayoutEffect, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { ListItem, Avatar, Button, Overlay } from 'react-native-elements';
import { SimpleLineIcons } from '@expo/vector-icons';
import Icon from 'react-native-vector-icons/SimpleLineIcons';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../assets/colors/colors';
import { auth, db } from '../firebase';
import { View } from 'react-native';
import { ScrollView } from 'react-native';
import firebase from 'firebase';
import { BlurView } from 'expo-blur';
import { ActivityIndicator } from 'react-native';

function UserList({ navigation }) {
    const user = auth.currentUser;

    const [userList, setUserList] = useState([]);
    const [showActivityIndicator, setShowActivityIndicator] = useState(false);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Alle User',
            headerStyle: {
                backgroundColor: Colors.primary,
            },
            headerTintColor: '#fff',
            headerTitleStyle: {
                fontWeight: 'bold',
            },
        });
    }, []);

    function updateUserList() {
        if (userList !== []) {
            var tempUserList = [];
            userList
                .filter((userFilter, index) => {
                    if (userFilter.id !== user.uid) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .forEach(async ({ id }, index) => {
                    var resData = await isChatCreated(id);
                    //console.log(result);
                    var tempUser = {
                        ...userList[index],
                        data: {
                            ...userList[index].data,
                            chatCreated: !resData.result,
                            chatID: resData.chatID,
                        },
                    };

                    tempUserList.push(tempUser);

                    if (tempUserList.length === userList.length) {
                        if (
                            JSON.stringify(tempUserList) !=
                            JSON.stringify(userList)
                        ) {
                            setUserList(tempUserList);
                        }
                    }
                });
        }
    }

    useEffect(() => {
        const unsub = db
            .collection('user')
            .where('visible', '!=', 'none')
            .onSnapshot((querySnapshot) => {
                setUserList(
                    querySnapshot.docs
                        .filter((doc) => doc.id !== user.uid)
                        .map((doc) => ({
                            id: doc.id,
                            data: doc.data(),
                        }))
                );
            });

        return unsub;
    }, []);

    useEffect(() => {
        updateUserList();
    });

    function openChat(chatID, users, partner, partnerID) {
        setShowActivityIndicator(true);
        navigation.navigate('singleChat', {
            chatID: chatID,
            users: users,
            partnerData: partner,
            partnerID: partnerID,
        });
        setShowActivityIndicator(false);
    }
    async function createChat(id, partnerData) {
        setShowActivityIndicator(true);
        if (await isChatCreated(id)) {
            db.collection('msg_rooms')
                .add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    type: 'single',
                    users: [id, user.uid],
                })
                .then((res) =>
                    openChat(res.id, [id, user.uid], partnerData, id)
                );
        }
    }

    async function isChatCreated(id) {
        const chatUserArray = [id, user.uid];
        const chatUserArray2 = [user.uid, id];

        const res = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray)
            .get();

        const res2 = await db
            .collection('msg_rooms')
            .where('users', '==', chatUserArray2)
            .get();

        //console.log(res.empty && res2.empty);

        var resChatId;

        if (!res.empty) {
            res.docs.forEach((doc) => (resChatId = doc.id));
        } else if (!res2.empty) {
            res2.docs.forEach((doc) => (resChatId = doc.id));
        }

        return { result: res.empty && res2.empty, chatID: resChatId };
    }

    function getInitials(displayName) {
        var initials = 'FW';

        if (displayName) {
            if (displayName.trim().indexOf(' ') != -1) {
                const fullName = displayName.split(' ');
                initials =
                    fullName.shift().charAt(0) + fullName.pop().charAt(0);
                initials = initials.toUpperCase();
            } else {
                initials = displayName.charAt(0);
            }
        }

        return initials;
    }

    return (
        <SafeAreaView edges={['left', 'right']} style={styles.container}>
            {showActivityIndicator ? (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 10,
                        //backgroundColor: 'blue',
                    }}
                >
                    <BlurView
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        intensity={30}
                    >
                        <ActivityIndicator size="large" />
                    </BlurView>
                </View>
            ) : (
                <></>
            )}
            <ScrollView>
                {userList.map(({ id, data }) => (
                    <ListItem
                        key={id}
                        bottomDivider
                        containerStyle={
                            {
                                /* backgroundColor: Colors.background, */
                            }
                        }
                        onPress={() =>
                            navigation.navigate('userDetails', {
                                userID: id,
                            })
                        }
                    >
                        <Avatar
                            size={48}
                            rounded
                            title={getInitials(data.displayName)}
                            containerStyle={{
                                backgroundColor: Colors.primaryDark,
                            }}
                            source={{ uri: data.photoURL }}
                        />
                        <ListItem.Content>
                            <ListItem.Title>{data.displayName}</ListItem.Title>
                            <ListItem.Subtitle style={{ fontWeight: '200' }}>
                                {data.status
                                    ? data.status
                                    : 'Kein Status angegeben.'}
                            </ListItem.Subtitle>
                        </ListItem.Content>
                        <Text
                            style={{
                                alignSelf: 'flex-start',
                                fontWeight: '200',
                            }}
                        >
                            {data.homeTown ? data.homeTown : ''}
                        </Text>
                        {data.acceptMSGWithOutFriends ? (
                            data.chatCreated ? (
                                <ListItem.Chevron
                                    name="speech"
                                    type="simple-line-icon"
                                    size={24}
                                    color={Colors.primary}
                                    onPress={() =>
                                        openChat(
                                            data.chatID,
                                            [user.uid, id],
                                            data,
                                            id
                                        )
                                    }
                                />
                            ) : (
                                <ListItem.Chevron
                                    name="plus"
                                    type="simple-line-icon"
                                    size={24}
                                    color={Colors.primary}
                                    onPress={() => createChat(id, data)}
                                />
                            )
                        ) : (
                            <ListItem.Chevron
                                name="arrow-right"
                                type="simple-line-icon"
                                size={24}
                                color={Colors.primary}
                            />
                        )}
                    </ListItem>
                ))}
            </ScrollView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        /* backgroundColor: Colors.background, */
        flex: 1,
    },
    headerContainer: {
        width: '100%',
        padding: SPACING.MARGIN / 2,
        paddingHorizontal: SPACING.MARGIN,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    goBackIcon: {
        justifyContent: 'center',
    },
    goBack: {
        fontSize: 18,
        width: '10%',
        color: 'black',
    },
    userCounter: {
        paddingLeft: SPACING.MARGIN / 2,
        fontSize: 16,
    },
    overlayContainer: {
        padding: SPACING.MARGIN,
    },
    overlayTitle: {
        fontSize: 32,
    },
});

export default UserList;
