import React, { useLayoutEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors/colors';
import { auth } from '../../firebase';
import { Input, Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/SimpleLineIcons';
import { View } from 'react-native';

function Login({ navigation }) {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Dashboard',
            headerShown: false,
        });
    }, []);

    function submitLogin() {
        if (email !== '' && password !== '') {
            auth.signInWithEmailAndPassword(email, password)
                .then((authUser) => {
                    if (authUser) {
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'home' }],
                        });
                    }
                })
                .catch((error) => alert(error));
        }
    }

    function resetPassword() {
        navigation.navigate('resetPassword', { userEmail: email });
    }

    return (
        <SafeAreaView style={styles.container}>
            <KeyboardAvoidingView
                style={{ width: '100%', alignItems: 'center' }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            >
                <Text style={styles.title}>Dein Login</Text>
                <Input
                    leftIcon={<Icon name="envelope" size={22} color="grey" />}
                    leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    containerStyle={{ width: '90%' }}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={{ fontSize: 18 }}
                    autoCapitalize="none"
                    onChangeText={(event) => setEmail(event)}
                    textContentType="emailAddress"
                    autoCompleteType="email"
                    keyboardType="email-address"
                    placeholder="Ihre E-Mail"
                />
                <Input
                    leftIcon={<Icon name="lock" size={22} color="grey" />}
                    leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    rightIcon={
                        <Icon
                            name="eye"
                            size={22}
                            color={showPassword ? 'grey' : 'lightgrey'}
                            onPress={() => setShowPassword(!showPassword)}
                        />
                    }
                    containerStyle={{ width: '90%' }}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={{ fontSize: 18 }}
                    onSubmitEditing={() => submitLogin()}
                    secureTextEntry={!showPassword}
                    onChangeText={(event) => setPassword(event)}
                    textContentType="password"
                    autoCompleteType="password"
                    keyboardType="default"
                    placeholder="Ihr Password"
                />
                <Button
                    onPress={() => submitLogin()}
                    iconRight
                    title="Anmelden"
                    icon={<Icon name="login" size={26} color="white" />}
                    containerStyle={{ width: '90%' }}
                    buttonStyle={styles.submitContainer}
                    iconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    titleStyle={styles.submitText}
                />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '90%',
                    }}
                >
                    <Button
                        onPress={() => navigation.goBack()}
                        title="Zurück"
                        type="clear"
                        icon={
                            <Icon name="arrow-left" size={16} color="white" />
                        }
                        containerStyle={styles.backBTNContainer}
                        iconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                        titleStyle={styles.backBTNText}
                    />
                    <Button
                        onPress={resetPassword}
                        title="Passwort vergessen"
                        type="clear"
                        containerStyle={styles.backBTNContainer}
                        titleStyle={styles.backBTNText}
                    />
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 48,
        color: 'white',
        fontWeight: '900',
        width: '90%',
        marginBottom: SPACING.MARGIN * 2,
    },
    inputContainer: {
        padding: SPACING.MARGIN / 3,
        paddingHorizontal: SPACING.MARGIN,
        backgroundColor: 'whitesmoke', // Colors.background,
        borderRadius: SPACING.MARGIN,
    },
    submitContainer: {
        backgroundColor: Colors.accent,
        alignItems: 'center',
        padding: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        marginTop: SPACING.MARGIN,
    },
    submitText: {
        color: 'white',
        fontSize: 24,
        fontWeight: 'normal',
    },
    backBTNContainer: {
        paddingVertical: SPACING.MARGIN,
    },
    backBTNText: {
        color: 'white',
        textDecorationLine: 'underline',
        textDecorationColor: 'white',
    },
});

export default Login;
