import React from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
import { Platform, Text } from 'react-native';
import { View } from 'react-native';
import { KeyboardAvoidingView, StyleSheet } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { Button } from 'react-native-elements/dist/buttons/Button';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Colors, SPACING } from '../../assets/colors/colors';
import { auth } from '../../firebase';

function ResetPassword({ navigation, route }) {
    const params = route.params;

    const [email, setEmail] = useState(params.userEmail);

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Passwort zurücksetzen',
            headerShown: false,
        });
    }, []);

    function sendResetLink() {
        if (email !== '') {
            auth.sendPasswordResetEmail(email).then(() => {
                alert('Email zum zurücksetzen des Passwortes versendet!');
                navigation.goBack();
            });
        }
    }

    return (
        <SafeAreaView edges={['left', 'right']} style={styles.container}>
            <KeyboardAvoidingView
                style={{ width: '100%', alignItems: 'center' }}
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            >
                <Text style={styles.title}>Passwort zurücksetzen</Text>
                <Input
                    leftIcon={
                        <Icon
                            name="envelope"
                            type="simple-line-icon"
                            size={22}
                            color="grey"
                        />
                    }
                    leftIconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                    containerStyle={{ width: '90%' }}
                    inputContainerStyle={styles.inputContainer}
                    inputStyle={{ fontSize: 18 }}
                    autoCapitalize="none"
                    onChangeText={(event) => setEmail(event)}
                    value={email}
                    textContentType="emailAddress"
                    autoCompleteType="email"
                    keyboardType="email-address"
                    placeholder="Ihre E-Mail"
                    onSubmitEditing={sendResetLink}
                />

                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '90%',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        onPress={() => navigation.goBack()}
                        title="Zurück"
                        type="clear"
                        icon={
                            <Icon
                                name="arrow-left"
                                type="simple-line-icon"
                                size={16}
                                color="white"
                            />
                        }
                        containerStyle={styles.backBTNContainer}
                        iconContainerStyle={{ marginRight: SPACING.MARGIN / 2 }}
                        titleStyle={styles.backBTNText}
                    />
                    <Button
                        onPress={sendResetLink}
                        title="Link senden"
                        containerStyle={styles.backBTNContainer}
                        buttonStyle={{
                            backgroundColor: Colors.accent,
                            padding: SPACING.MARGIN / 2,
                            borderRadius: SPACING.MARGIN,
                            paddingHorizontal: SPACING.MARGIN,
                        }}
                    />
                </View>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.primary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    title: {
        fontSize: 48,
        color: 'white',
        fontWeight: '900',
        width: '90%',
        marginBottom: SPACING.MARGIN * 2,
    },
    inputContainer: {
        padding: SPACING.MARGIN / 3,
        paddingHorizontal: SPACING.MARGIN,
        backgroundColor: 'whitesmoke', // Colors.background,
        borderRadius: SPACING.MARGIN,
    },
    submitContainer: {
        backgroundColor: Colors.accent,
        alignItems: 'center',
        padding: SPACING.MARGIN,
        borderRadius: SPACING.MARGIN,
        marginTop: SPACING.MARGIN,
    },
    submitText: {
        color: 'white',
        fontSize: 24,
        fontWeight: 'normal',
    },
    backBTNContainer: {
        paddingVertical: SPACING.MARGIN,
    },
    backBTNText: {
        color: 'white',
        textDecorationLine: 'underline',
        textDecorationColor: 'white',
    },
});
export default ResetPassword;
