import React from 'react';
import { useLayoutEffect } from 'react';
import { Platform } from 'react-native';
import {
    Dimensions,
    Image,
    StyleSheet,
    Text,
    TouchableHighlight,
    TouchableOpacity,
    TouchableWithoutFeedback,
    View,
} from 'react-native';
import { useEffect } from 'react/cjs/react.development';
import { Colors, SPACING } from '../../assets/colors/colors';
import { auth } from '../../firebase';

function FirstUp({ navigation }) {
    function doNothing(params) {}

    useLayoutEffect(() => {
        navigation.setOptions({
            title: 'Willkommen',
            headerShown: false,
        });
    }, []);

    return (
        <View style={styles.container}>
            <View
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    backgroundColor: Colors.primary,
                }}
            >
                <View
                    style={{
                        width: '100%',
                        display: 'flex',
                        padding: SPACING.MARGIN,
                        paddingTop: SPACING.MARGIN * 2,
                    }}
                >
                    <Text style={styles.title}>Willkommen</Text>
                    <Text style={styles.subtitle}>
                        Dein Buddy für selbstbestimmes Wohnen!
                    </Text>
                    <Image
                        source={require('../../assets/wheelchair.png')}
                        style={{
                            width: '100%',
                            height: 500,
                            marginTop: SPACING.MARGIN,
                        }}
                        resizeMode="contain"
                    />
                    <TouchableHighlight
                        style={styles.button}
                        underlayColor={Colors.accentDark}
                        onPress={() => navigation.push('login')}
                    >
                        <View style={{}}>
                            <Text style={styles.buttonText}>Anmelden</Text>
                        </View>
                    </TouchableHighlight>
                    <TouchableOpacity
                        style={styles.Textbutton}
                        onPress={() => navigation.push('register')}
                    >
                        <View style={{}}>
                            <Text style={styles.TextbuttonText}>
                                Registrieren
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.background,
    },
    title: {
        fontSize: 48,
        fontWeight: 'bold',
        color: Colors.background,
    },
    subtitle: {
        fontSize: 24,
        color: Colors.background,
        fontWeight: '300',
    },
    button: {
        backgroundColor: Colors.accent,
        padding: SPACING.MARGIN,
        marginTop: SPACING.MARGIN,
        alignItems: 'center',
        borderRadius: 25,
    },
    Textbutton: {
        alignItems: 'center',
        padding: SPACING.MARGIN,
        paddingBottom: 0,
    },
    TextbuttonText: {
        color: Colors.background,
        textDecorationColor: Colors.background,
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',
        fontSize: 14,
    },
    buttonText: {
        fontWeight: '600',
        color: Colors.background,
        fontSize: 24,
    },
});

export default FirstUp;
